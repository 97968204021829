import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TradesContext, UserContext } from "../App";
import MenuIcon from '@mui/icons-material/Menu';
import HeaderHamMenu from "./HeaderHamMenu";

export default function Header() {
	const { trades, setTrades, fetchedTrades, setFetchedTrades, currentlyUsedEkTotalDay, currentlyUsedEkTotalSwing } = useContext(TradesContext);
	const { isLoggedIn, setIsLoggedIn, numTradesAllowed, setNumTradesAllowed, currentUser, setCurrentUser } = useContext(UserContext);

	return (
		<div style={{ display: "flex", flexDirection: "row", gap: "6px", width: "100%" }}>
			{
				isLoggedIn &&
				<div style={{ display: "flex", paddingLeft: "10px", paddingRight: "10px" }}>
					<HeaderHamMenu />
				</div>
			}
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<a href="/">
					<img src={"../img1.png"} height={32} style={{ margin: 8, paddingTop: 8 }}></img>
				</a>
			</div>
		</div>
	);
}