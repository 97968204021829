import { Button, CircularProgress, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import { MenuContext } from "../App";
import { TradesContext } from "../App";
import TradeRowMobile from "./TradeRowMobile";

export default function TradesTableMobile(props) {
	const [tableHeight, setTableHeight] = useState(400); // Default height
	const { isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen } = useContext(MenuContext);
	const { tradesLoadedMultiplier, setTradesLoadedMultiplier, selectedTrade, setSelectedTrade } = useContext(TradesContext);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		// Calculate available height between header and footer
		const headerHeight = document.getElementById('header').offsetHeight;
		var filterMenuHeight = 0;
		if (isFilterMenuOpen) {
			filterMenuHeight = 68; //document.getElementById('filter-menu').offsetHeight; //TODO: dumt system, men funkar, hitta height på filtermenyn manuellt
		}
		const availableHeightTmp = window.innerHeight - headerHeight - filterMenuHeight;

		// Set the height of the table dynamically
		setTableHeight(availableHeightTmp);
	}, [isFilterMenuOpen]);

	async function handleLoadMore() {
		setIsLoading(true);
		await new Promise(resolve => setTimeout(resolve, 100));
		setTradesLoadedMultiplier(prev => prev + 1);
		await new Promise(resolve => setTimeout(resolve, 100));
		setIsLoading(false);
	}

	return (
		<TableContainer sx={{ width: "100%", scrollbarWidth: "thin" }} className="small-scrollbar">
			<Table stickyHeader aria-label="sticky table">
				<TableHead>
					<TableRow>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
								<p style={{ padding: 0, margin: 0 }}>Öppnad</p>
								<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Trader</p>
							</div>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
								<p style={{ padding: 0, margin: 0 }}>Size</p>
								<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Häv</p>
							</div>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
								<p style={{ padding: 0, margin: 0 }}>Ticker</p>
								<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>Typ</p>
							</div>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}> </TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => { setIsNewTradeMenuOpen(true); setSelectedTrade(null); }}
							>
								<AddIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.trades.slice(0, Math.min(100 * tradesLoadedMultiplier, props.trades.length)).map((trade, idx, arr) => (
						<>
							<TradeRowMobile key={trade.id + "r"} trade={trade} />
							{idx == arr.length - 1 ?
								<TableRow key={trade.id + "end"} style={{ backgroundColor: "rgb(27, 30, 38)" }}>
									<TableCell colSpan={5} sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
										{props.trades.length > 100 * tradesLoadedMultiplier ?
											<>
												<Button
													sx={{ mt: 1, mb: 1, mr: 1 }}
													variant="outlined"
													color="primary"
													onClick={() => { handleLoadMore() }}>
													Ladda fler trades
												</Button>
												{isLoading ?
													<CircularProgress size={20} color="primary" />
													: null}
											</>
											:
											<>
												<p style={{ padding: 0, margin: 0 }}>Inga fler trades att ladda</p>
											</>
										}
									</TableCell>
								</TableRow>
								: null}
						</>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}