import { Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FilterContext, TradesContext } from "../App";
import { translateUserIdToName } from "../lib/helpFunc";
import ReplayIcon from '@mui/icons-material/Replay';

export default function FilterMenu(props) {
	const { tradeFilter, setTradeFilter, allInstruments, allTraders } = useContext(FilterContext);

	const [filterHeaders, setFilterHeaders] = useState();
	const [filterInputs, setFilterInputs] = useState();

	const instruments = allInstruments;
	const names = allTraders;

	useEffect(() => {
		if (tradeFilter) {
			var headers = [];
			var inputs = [];
			for (const [key, value] of Object.entries(tradeFilter)) {
				headers.push(key);
				inputs.push(value);
			}
			console.log('headers', headers);
			console.log('inputs', inputs);
			setFilterHeaders(headers);
			setFilterInputs(inputs);
		}
	}, [tradeFilter]);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Rensa</TableCell>
						{
							filterHeaders && filterHeaders.map((header, index) => {
								return <TableCell key={index}>{String(header).charAt(0).toUpperCase() + String(header).slice(1)}</TableCell>
							})
						}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							<IconButton
								aria-label="clear filters"
								size="small"
								onClick={() => {
									setTradeFilter({
										"trader": {
											"type": "string",
											"value": []
										},
										"open": { "type": "boolean", "value": true },
										"closed": { "type": "boolean", "value": false },
										"ticker": {
											"type": "string",
											"value": []
										},
										"long": { "type": "boolean", "value": true },
										"short": { "type": "boolean", "value": true },
										"swing": { "type": "boolean", "value": true },
										"day": { "type": "boolean", "value": true },
									})
								}}
							>
								<ReplayIcon />
							</IconButton>
						</TableCell>
						{
							filterInputs && filterInputs.map((input, index) => {
								if (input.type == "boolean") {
									return (
										<TableCell key={index}>
											<Checkbox
												sx={{ paddingLeft: 0 }}
												checked={tradeFilter[filterHeaders[index]].value}
												onChange={(e) => {
													setTradeFilter({
														...tradeFilter,
														[filterHeaders[index]]: {
															...tradeFilter[filterHeaders[index]],
															value: e.target.checked
														}
													})
												}}
											/>
										</TableCell>
									)
								} else if (input.type == "string") {
									return (
										<TableCell key={index}>
											<FormControl sx={{ width: "160px" }} size="small">
												<InputLabel id={filterHeaders[index] + "label"}>{/*filterHeaders[index]*/}</InputLabel>
												<Select
													/*labelId={filterHeaders[index] + "label"}*/
													id={filterHeaders[index]}
													multiple
													value={tradeFilter[filterHeaders[index]].value}
													onChange={(e) => {
														setTradeFilter({
															...tradeFilter,
															[filterHeaders[index]]: {
																...tradeFilter[filterHeaders[index]],
																value: e.target.value
															}
														})
													}}
													input={<OutlinedInput /*label={filterHeaders[index]}*/ />}
													displayEmpty
													renderValue={(selected) => {
														//if no filter is selected, display "All"
														if (selected.length == 0) {
															return ("Allting")
														}
														if (selected.length > 3) {
															return (selected.length + " valda")
														}
														if (filterHeaders[index] == "trader") {
															selected = selected.map((id) => {
																return (id.name)
															})
														}
														return (selected.join(', '))
													}}
												>
													{
														filterHeaders[index] == "trader" ? names.map((name) => {
															return (<MenuItem key={name.id} value={name}>
																<Checkbox checked={tradeFilter[filterHeaders[index]].value.some(obj => obj.id == name.id)} />
																<ListItemText primary={(name.name)} />
															</MenuItem>)
														})
															:
															filterHeaders[index] == "ticker" ? instruments.map((instrument) => {
																return (<MenuItem key={instrument} value={instrument}>
																	<Checkbox checked={tradeFilter[filterHeaders[index]].value.indexOf(instrument) > -1} />
																	<ListItemText primary={instrument} />
																</MenuItem>)
															})
																:
																null
													}
												</Select>
											</FormControl>
										</TableCell>
									)
								}
								return <TableCell key={index}>{String(input.value)}</TableCell>
							})
						}
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}