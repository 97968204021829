import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { TradesContext, UserContext } from "../App";
import MenuIcon from '@mui/icons-material/Menu';
import HeaderHamMenu from "./HeaderHamMenu";

export default function HeaderMobile() {
	const { trades, setTrades, fetchedTrades, setFetchedTrades, currentlyUsedEkTotalDay, currentlyUsedEkTotalSwing } = useContext(TradesContext);
	const { isLoggedIn, setIsLoggedIn, numTradesAllowed, setNumTradesAllowed, currentUser, setCurrentUser } = useContext(UserContext);

	const [tradesTakenTodayDay, setTradesTakenTodayDay] = useState();
	const [tradesTakenTodaySwing, setTradesTakenTodaySwing] = useState();
	const [ekUsedDay, setEkUsedSwing] = useState();
	const [activeTrades, setActiveTrades] = useState();
	const [closedTrades, setClosedTrades] = useState();

	useEffect(() => {
		if (fetchedTrades.length > 0) {
			// Calculate trades taken today
			const today = new Date().toISOString().slice(0, 10);
			setTradesTakenTodayDay(fetchedTrades.filter(trade => trade.user.id == currentUser && trade.status == "open" && trade.events[trade.events.length - 1].timestamp.includes(today) && trade.category.includes("daytrades")).length);
			setTradesTakenTodaySwing(fetchedTrades.filter(trade => trade.user.id == currentUser && trade.status == "open" && trade.events[trade.events.length - 1].timestamp.includes(today) && trade.category.includes("Swings")).length);

			setActiveTrades(fetchedTrades.filter(trade => trade.status == "open").length);
			setClosedTrades(fetchedTrades.filter(trade => trade.status == "closed").length);
		}
	}, [fetchedTrades]);

	return (
		<div style={{ display: "flex", flexDirection: "row", gap: "6px", width: "100%" }}>
			{
				isLoggedIn &&
				<div style={{ flex: 0.5, display: "flex" }}>
					<HeaderHamMenu showExtendedMenu />
				</div>
			}
			<div style={{ flex: 6, display: "flex" }}>
				{
					isLoggedIn &&
					<>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										Aktiva trades
									</TableCell>
									<TableCell>
										Använt EK
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell style={{ border: 'none' }}>
										<Typography variant="p">Day: {tradesTakenTodayDay ?? "-"}/{numTradesAllowed ?? ""} </Typography>
										<br></br>
										<Typography variant="p">Swing: {tradesTakenTodaySwing ?? "-"}/{numTradesAllowed ?? ""}</Typography>
									</TableCell>
									<TableCell style={{ border: 'none' }}>
										<Typography variant="p">Day: {currentlyUsedEkTotalDay + "%" ?? "-"}</Typography>
										<br></br>
										<Typography variant="p">Swing: {currentlyUsedEkTotalSwing + "%" ?? "-"}</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</>
				}
			</div>
			<div style={{ flex: 0.5, display: "flex", alignItems: "center", justifyContent: "center" }}>
				<a href="/">
					<img src={"../img1.png"} height={28} style={{ margin: 8, paddingTop: 8 }}></img>
				</a>
			</div>
		</div>
	);
}