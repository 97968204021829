
import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';
import { isNumericString } from '../lib/helpFunc';

export const LinechartComponent = props => {
	const {
		data,
		colors: {
			backgroundColor = '#1e222d',
			lineColor = '#009fcf',
			textColor = 'rgba(255,255,255,0.87)',
		} = {},
		events,
		levels,
	} = props;

	const chartContainerRef = useRef();

	useEffect(
		() => {
			const chart = createChart(chartContainerRef.current, {
				layout: {
					background: { type: ColorType.Solid, color: backgroundColor },
					textColor,
				},
				grid: {
					vertLines: {
						color: 'rgba(255,255,255,0.038)',
					},
					horzLines: {
						color: 'rgba(255,255,255,0.138)',
					},
				},
				timeScale: {
					rightOffset: 5,
					autoScale: false,
					timeVisible: true,
					secondsVisible: true,
					visible: true,
					ticksVisible: false,
					tickMarkFormatter: function (time) {
						return new Date(time).toLocaleDateString()
					}
				},
				localization: {
					timeFormatter: function (time) {
						return new Date(time).toLocaleString()
					}
				},
				width: chartContainerRef.current.clientWidth,
				height: 300,
			});
			chart.timeScale().fitContent();

			const newSeries = chart.addLineSeries({ lineColor, color: lineColor, priceLineVisible: false, lastValueVisible: false, lineWidth: 2 });
			newSeries.setData(data);

			levels.forEach(level => {
				if (level.price && isNumericString(level.price) &&
					level.color &&
					level.name) {
					newSeries.createPriceLine({
						price: Number(String(level.price)?.replace(',', '.')),
						color: level.color,
						lineWidth: 1,
						lineStyle: 0,
						axisLabelVisible: true,
						title: level.name,
					});
				}
			});

			if (events && events.length > 0) {
				const markers = [];

				console.log(events)
				events.sort((a, b) => (a.time > b.time) ? 1 : -1);
				events.forEach(event => {
					if (event.time &&
						event.position &&
						event.shape &&
						event.color) {
						markers.push({
							time: Number(event.time),
							position: event.position,
							shape: event.shape,
							color: event.color,
							id: event.id,
							text: event.text,
							size: event.size,
						});
					}
				});

				console.log(markers)

				newSeries.setMarkers(markers);
			}

			const handleResize = () => {
				chart.applyOptions({ width: chartContainerRef.current.clientWidth });
			};

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);

				chart.remove();
			};
		},
		[data, events, levels, backgroundColor, lineColor, textColor]
	);

	return (
		<div
			style={{ border: "1px solid rgba(255,255,255,0.38)", zIndex: 999 }}
			ref={chartContainerRef}
		/>
	);
};