import { useContext, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import InfoIcon from '@mui/icons-material/Info';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { IconButton } from '@mui/material';
import { AppContext, MenuContext, TradesContext, UserContext } from '../App';

export default function HeaderHamMenu(props) {
	const { isMobileDevice, setIsMobileDevice } = useContext(AppContext);
	const { isLoggedIn, setIsLoggedIn, numTradesAllowed, setNumTradesAllowed, currentUser, setCurrentUser, cookies } = useContext(UserContext);
	const { isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen, isStatisticsMenuOpen, setIsStatisticsMenuOpen } = useContext(MenuContext);
	const { selectedTrade, setSelectedTrade, selectedEvent, setSelectedEvent } = useContext(TradesContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		setCurrentUser(null);
		setIsLoggedIn(false);
		cookies.remove("ckJwt");
		cookies.remove("ckIsLoggedIn");
		cookies.remove("ckCurrentUser");
	}

	const handleSwitchDevice = () => {
		setAnchorEl(null);
		cookies.set("ckIsMobileDevice", !isMobileDevice);
		setIsMobileDevice((prev) => !prev);
	}

	const handleShowStatsMenu = () => {
		setAnchorEl(null);
		setSelectedTrade(null);
		setSelectedEvent(null);
		setIsNewTradeMenuOpen(false);
		setIsEditEventMenuOpen(false);
		setIsStatisticsMenuOpen(true);
	}

	return (
		<>
			<IconButton
				aria-label="open menu"
				size="small"
				onClick={handleClick}
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				<MenuIcon />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<Paper sx={{ width: 320, maxWidth: '100%' }}>
					<MenuList>
						<MenuItem onClick={handleSwitchDevice}>
							<ListItemIcon>
								{
									props.showExtendedMenu ? <DesktopWindowsOutlinedIcon /> : <PhoneIphoneOutlinedIcon />
								}
							</ListItemIcon>
							<ListItemText>{props.showExtendedMenu ? "Desktopversion" : "Mobilversion"}</ListItemText>
						</MenuItem>
						<MenuItem onClick={handleShowStatsMenu}>
							<ListItemIcon>
								<EqualizerIcon />
							</ListItemIcon>
							<ListItemText>Statistik</ListItemText>
						</MenuItem>
						<MenuItem disabled onClick={handleClose}>
							<ListItemIcon>
								<FileDownloadIcon />
							</ListItemIcon>
							<ListItemText>Export</ListItemText>
						</MenuItem>
						<Divider />
						{
							props.showExtendedMenu &&
							<>
								<MenuItem disabled onClick={handleClose}>
									<ListItemIcon>
										<InfoIcon />
									</ListItemIcon>
									<ListItemText>Öppna trade</ListItemText>
								</MenuItem>
								<MenuItem disabled onClick={handleClose}>
									<ListItemIcon>
										<EqualizerIcon />
									</ListItemIcon>
									<ListItemText>Visa alla trades</ListItemText>
								</MenuItem>
								<MenuItem onClick={() => { setIsFilterMenuOpen(!isFilterMenuOpen); handleClose(); }}>
									<ListItemIcon>
										<FileDownloadIcon />
									</ListItemIcon>
									<ListItemText>Filter</ListItemText>
								</MenuItem>
								<Divider />
							</>
						}
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<LogoutIcon />
							</ListItemIcon>
							<ListItemText>Logga ut</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>

			</Menu>
		</>
	);
}

