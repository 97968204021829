import { Button, FormControl, FormGroup, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useState } from "react";
import { UserContext } from "../App";
import { loginUser } from "../lib/network";
import { jwtDecode } from "jwt-decode";

export default function Login() {
  const { isLoggedIn, setIsLoggedIn, cookies, setCurrentUser } = useContext(UserContext);
  const [usernameInput, setUsernameInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log("Try to login");
    setErrorMessage("");
    setIsLoading(true);

    //if (usernameInput === "" && passwordInput === "") {
    //  setIsLoggedIn(true);
    //}
    try {
      if (usernameInput !== "" && passwordInput !== "") {
        const loginResult = await loginUser(usernameInput, passwordInput, null, "web", "1.0.53");
        console.log("loginResult: ", loginResult);
        if (loginResult.success) {
          setIsLoggedIn(true);
          setCurrentUser(loginResult.user);
          if (loginResult.jwt) {
            const decoded = jwtDecode(loginResult.jwt);
            console.log("decoded: ", decoded);
            console.log("setting expiry to", new Date(decoded.exp * 1000));
            cookies.set("ckJwt", loginResult.jwt, {
              expires: new Date(decoded.exp * 1000),
            })
            cookies.set("ckIsLoggedIn", "true", {
              expires: new Date(decoded.exp * 1000)
            })
            cookies.set("ckCurrentUser", JSON.stringify(loginResult.user), {
              expires: new Date(decoded.exp * 1000)
            })
          }
        } else {
          setErrorMessage(loginResult.msg);
        }
      } else {
        setErrorMessage("Du måste fylla i användarnamn och lösenord");
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("Okänt fel, kontakta admin");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "50%" }}>
      <form onSubmit={(e) => { handleLogin(e) }} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Användarnamn</InputLabel>
          <OutlinedInput
            id="outlined-adornment-username"
            value={usernameInput}
            onChange={(event) => {
              setUsernameInput(event.target.value);
            }}
            label="Användarnamn"
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={passwordInput}
            onChange={(event) => {
              setPasswordInput(event.target.value);
            }}
            onSubmit={(event) => { handleLogin() }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Button
          size="large"
          type="submit"
          sx={{ mt: 1 }}
          onClick={(e) => { handleLogin(e) }}
          variant="outlined"
          endIcon={<SendIcon />}>
          Logga in
        </Button>
      </form>
      {isLoading ? <CircularProgress /> : null}
      {errorMessage !== "" && <Typography sx={{ mt: 1, fontSize: 12, color: "red" }}> {errorMessage} </Typography>}
    </div>
  );
}