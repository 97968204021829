import { Button, Checkbox, FormControl, Hidden, IconButton, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import TuneIcon from '@mui/icons-material/Tune';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import TradeRow from "./TradeRow";
import { MenuContext, TradesContext } from "../App";
import { convertDateToRelevant, isNumericString, translateCategoryToDaySwing, translateStatusToText, translateTypeToText, translateUserIdToName } from "../lib/helpFunc";
import { createChart } from 'lightweight-charts';
import { LinechartComponent } from "./LinechartComponent";
import { changeTrade, closeTrade, deleteEvent, deleteTrade, fillTrade, followTrade, getAllTrades, getAvanzaChartDataViaProxy, getLivePriceOnSearchedSymbol, unfollowTrade } from "../lib/network";
import Tooltip from '@mui/material/Tooltip';

const toShortenedText = (text) => {
	if (text.length > 25) {
		return text.substring(0, 22) + "..."
	}
	return text
}

//  ███████ ██    ██ ███    ██  ██████ 
//  ██      ██    ██ ████   ██ ██      
//  █████   ██    ██ ██ ██  ██ ██      
//  ██      ██    ██ ██  ██ ██ ██      
//  ██       ██████  ██   ████  ██████ 

export default function StatsMenu(props) {
	const [tableHeight, setTableHeight] = useState(400); // Default height
	const { isFilterMenuOpen, setIsFilterMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen, isStatisticsMenuOpen, setIsStatisticsMenuOpen } = useContext(MenuContext);
	const { trades, setTrades, selectedTrade, setSelectedTrade, selectedEvent, setSelectedEvent, userFollowedTrades, setUserFollowedTrades } = useContext(TradesContext);

	const [statisticsRows, setStatisticsRows] = useState([]);
	const [fetchedTradesTotal, setFetchedTradesTotal] = useState([]);
	const [tradesToDeriveStatsFrom, setTradesToDeriveStatsFrom] = useState([]);
	const [isShowingLocalTrades, setIsShowingLocalTrades] = useState(false);
	const [linechartData, setLinechartData] = useState([]);
	const [tradesToDrawLinechartFrom, setTradesToDrawLinechartFrom] = useState([]);
	const [portfolioValuesLinedata, setPortfolioValuesLinedata] = useState([]);
	const [portfolioPercentageLinedata, setPortfolioPercentageLinedata] = useState([]);
	const [portfolioWithCommissionLinedata, setPortfolioWithCommissionLinedata] = useState([]);

	useEffect(() => {
		// Calculate available height between header and footer
		const headerHeight = document.getElementById('header').offsetHeight;
		const footerHeight = document.getElementById('footer').offsetHeight;
		var filterMenuHeight = 0;
		if (isFilterMenuOpen) {
			filterMenuHeight = 68; //document.getElementById('filter-menu').offsetHeight; //TODO: dumt system, men funkar, hitta height på filtermenyn manuellt
		}
		const availableHeightTmp = window.innerHeight - headerHeight - footerHeight - filterMenuHeight;

		// Set the height of the table dynamically
		setTableHeight(availableHeightTmp);
	}, [isFilterMenuOpen]);

	const statsRows1 = [
		{ title: "abc", value: "123" },
		{ title: "abc2", value: "1232" },
		{ title: "abc3", value: "1233" },
	]

	useEffect(() => {
		console.log("Trades changed")
		console.log(trades)
		if (isShowingLocalTrades) {
			setTradesToDeriveStatsFrom(trades);
		}

	}, [trades]);

	const convertEventTimestampToChartdate = (timestamp) => {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		var month = date.getMonth() + 1;
		var day = date.getDate();
		const hour = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		if (month < 10) { month = "0" + month; }
		if (day < 10) { day = "0" + day; }
		return year + "-" + month + "-" + day;
	}

	useEffect(() => {
		console.log("trades to calculate stats from:")
		console.log(tradesToDeriveStatsFrom)
		if (tradesToDeriveStatsFrom && tradesToDeriveStatsFrom.length > 2) {
			setTradesToDrawLinechartFrom([]);
			var trds = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open");

			trds = trds.filter((trade) => {
				if (trade.events.length == 0) {
					return false;
				}
				return true;
			});

			//const numWinningTrades = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open" && trade.net_profit > 0).length;
			//const numLosingTrades = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open" && trade.net_profit < 0).length;
			const numWinningTrades = trds.filter(trade => trade.net_profit > 0).length;
			const numLosingTrades = trds.filter(trade => trade.net_profit < 0).length;

			const winrate = numWinningTrades / (numWinningTrades + numLosingTrades);

			//const totGain = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open" && trade.net_profit > 0).reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);
			//const totLoss = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open" && trade.net_profit < 0).reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);
			//const totResult = tradesToDeriveStatsFrom.filter(trade => trade.status !== "open").reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);

			const totGain = trds.filter(trade => trade.net_profit > 0).reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);
			const totLoss = trds.filter(trade => trade.net_profit < 0).reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);
			const totResult = trds.reduce((acc, trade) => acc + trade.net_profit / trade.size, 0);

			const totGainPortfolio = trds.filter(trade => trade.net_profit > 0).reduce((acc, trade) => acc + trade.net_profit / (100 * trade.size), 0);
			const totLossPortfolio = trds.filter(trade => trade.net_profit < 0).reduce((acc, trade) => acc + trade.net_profit / (100 * trade.size), 0);
			const totResultPortfolio = trds.reduce((acc, trade) => acc + trade.net_profit / (100 * trade.size), 0);

			//%gain graph calculations
			var totNumCommissionableEvents = 0;
			trds.sort((a, b) => {
				return a.events[0].timestamp - b.events[0].timestamp < 0 ? -1 : 1;
			})
			for (var i = 0; i < trds.length; i++) {
				trds[i].closeTimeDate = convertEventTimestampToChartdate(trds[i].events[0].timestamp);
				totNumCommissionableEvents += trds[i].events.reduce((acc, event) => {
					if (event.type === "open" || event.type === "fill" || event.type === "close") {
						return acc + 1;
					}
					return acc;
				}, 0);
				trds[i].commission = trds[i].events.reduce((acc, event) => {
					if (event.type === "open" || event.type === "fill" || event.type === "close") {
						return Number(acc) + 19;
					}
					return acc;
				}, 0)
			}
			//sum up net_profit for each day
			const daySums = trds.reduce((acc, trade) => {
				const date = trade.closeTimeDate;
				if (acc[date]) {
					acc[date] += trade.net_profit / trade.size;
				} else {
					acc[date] = trade.net_profit / trade.size;
				}
				return acc;
			}, {});

			//sum up commision for each day
			const daySumsCommission = trds.reduce((acc, trade) => {
				const date = trade.closeTimeDate;
				if (acc[date]) {
					acc[date] += trade.commission;
				} else {
					acc[date] = trade.commission;
				}
				return acc;
			}, {});

			const daySumsCommissionArray = Object.keys(daySumsCommission).map((key) => {
				return {
					time: key,
					value: daySumsCommission[key],
				}
			})

			//convert daySums key-value pairs to array of objects
			const daySumsArray = Object.keys(daySums).map((key) => {
				return {
					time: key,
					value: daySums[key],
				}
			})

			const daySumsPortfolio = trds.reduce((acc, trade) => {
				const date = trade.closeTimeDate;
				if (acc[date]) {
					acc[date] += trade.net_profit / (100 * trade.size);
				} else {
					acc[date] = trade.net_profit / (100 * trade.size);
				}
				return acc;
			}, {});

			const daySumsArrayPortfolio = Object.keys(daySumsPortfolio).map((key) => {
				return {
					time: key,
					value: daySumsPortfolio[key],
				}
			})

			//Räkna ut portföljutveckling med compound interest
			var portfolioValue = 100000;
			var portfolioValueArray = [];
			var portfolioValueWithCommission = 100000;
			var portfolioValueWithCommissionArray = [];
			for (var i = 0; i < Object.keys(daySums).length; i++) {
				portfolioValue = portfolioValue + (portfolioValue * daySumsArrayPortfolio[i].value / 100);
				portfolioValueArray.push(portfolioValue);
				portfolioValueWithCommission = portfolioValueWithCommission + (portfolioValueWithCommission * daySumsArrayPortfolio[i].value / 100) - daySumsCommissionArray[i].value;
				portfolioValueWithCommissionArray.push(portfolioValueWithCommission);
			}

			daySumsArray.sort((a, b) => {
				return new Date(a.time) - new Date(b.time) < 0 ? -1 : 1;
			})

			//combine all values to day i+1 = day i + day i+1
			for (var i = 1; i < daySumsArray.length; i++) {
				daySumsArray[i].value += daySumsArray[i - 1].value;
			}

			setTradesToDrawLinechartFrom(daySumsArray)

			daySumsArrayPortfolio.sort((a, b) => {
				return new Date(a.time) - new Date(b.time) < 0 ? -1 : 1;
			})

			//combine all values to day i+1 = day i + day i+1
			for (var i = 1; i < daySumsArrayPortfolio.length; i++) {
				daySumsArrayPortfolio[i].value += daySumsArrayPortfolio[i - 1].value;
			}

			setPortfolioPercentageLinedata(daySumsArrayPortfolio)

			setPortfolioValuesLinedata(portfolioValueArray.map((value, index) => {
				return {
					time: daySumsArray[index].time,
					value: value
				}
			}));

			setPortfolioWithCommissionLinedata(portfolioValueWithCommissionArray.map((value, index) => {
				return {
					time: daySumsArray[index].time,
					value: value
				}
			}));

			setStatisticsRows([
				{ title: "Antal trades", value: trds.length },
				{ title: "Antal trades öppna", value: tradesToDeriveStatsFrom.filter(trade => trade.status === "open").length },
				{ title: "Antal trades stängda", value: tradesToDeriveStatsFrom.filter(trade => trade.status !== "open").length },
				{ title: "Antal trades vinst", value: trds.filter(trade => trade.net_profit > 0).length },
				{ title: "Antal trades förlust", value: trds.filter(trade => trade.net_profit < 0).length },
				{ title: "Antal trades BE", value: trds.filter(trade => trade.net_profit === 0).length },
				{ title: "Winrate", value: Number((Number(winrate) * 100)).toFixed(1) + "%" },
				{ title: "Total vinst", value: Number(totGain).toFixed(1) + "%" },
				{ title: "Total förlust", value: Number(totLoss).toFixed(1) + "%" },
				{ title: "Totalt resultat", value: Number(totResult).toFixed(1) + "%" },
				{ title: "Total vinst (portfölj)", value: Number(totGainPortfolio).toFixed(1) + "%" },
				{ title: "Total förlust (portfölj)", value: Number(totLossPortfolio).toFixed(1) + "%" },
				{ title: "Totalt resultat (portfölj)", value: Number(totResultPortfolio).toFixed(1) + "%" },
				{ title: "Portföljvärde (100.000kr start)", value: Number(portfolioValue).toFixed(0) + " kr" },
				{ title: "Antal händelser med courtage", value: totNumCommissionableEvents },
				{ title: "Totalt courtage (19kr/händelse)", value: Number(19 * totNumCommissionableEvents).toFixed(0) + " kr" },
				{ title: "Portföljvärde efter courtage (19kr)", value: (Number(portfolioValueWithCommission).toFixed(0)) + " kr" }
			])
		}
	}, [tradesToDeriveStatsFrom, trades, fetchedTradesTotal]);

	useEffect(() => {
		if (tradesToDrawLinechartFrom.length > 10) {
			setLinechartData(tradesToDrawLinechartFrom);
		} else {
			setLinechartData([]);
		}
	}, [tradesToDrawLinechartFrom]);

	useEffect(() => {
		//fetch ALL trades, in order to derive stats from them
		async function getTrades() {
			const response = await getAllTrades(setTradesToDeriveStatsFrom, { limit: 9999, events: true });
			setFetchedTradesTotal(response);
		}
		getTrades();
	}, []);

	const switchTrades = () => {
		if (isShowingLocalTrades) {
			setTradesToDeriveStatsFrom(fetchedTradesTotal);
		} else {
			setTradesToDeriveStatsFrom(trades);
		}
		setIsShowingLocalTrades(!isShowingLocalTrades);
	}

	return (
		<TableContainer sx={{ width: "100%", maxHeight: tableHeight - 4, scrollbarWidth: "thin" }} className="small-scrollbar">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							Statistik
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => setIsStatisticsMenuOpen(false)}
							>
								<CloseIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								Statistik
							</TableCell>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								{isShowingLocalTrades ? "Visar just nu trades i listan till vänster" : "Visar just nu samtliga trades"} &nbsp; &nbsp; &nbsp; <Button variant="outlined" onClick={switchTrades}>Byt till {isShowingLocalTrades ? "alla trades" : "valda trades"}</Button>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							statisticsRows.map((row, index) => {
								return (
									<TableRow>
										<TableCell sx={{ borderRight: "1px solid #515151", textAlign: "right", width: "35%" }}>
											{row.title}
										</TableCell>
										<TableCell>
											{row.value}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableBody>
						<p>Utveckling %:</p>
						{linechartData.length > 10 ?
							<LinechartComponent data={linechartData} levels={[]}
								events={[]}
							></LinechartComponent>
							: null
						}
						<p>Portfölj %:</p>
						{portfolioPercentageLinedata.length > 10 ?
							<LinechartComponent data={portfolioPercentageLinedata} levels={[]}
								events={[]}
							></LinechartComponent>
							: null
						}
						<p>Portfölj med 100.000kr:</p>
						{portfolioValuesLinedata.length > 10 ?
							<LinechartComponent data={portfolioValuesLinedata} levels={[]}
								events={[]}
							></LinechartComponent>
							: null
						}
						<p>Portfölj med 100.000kr, courtage medräknat:</p>
						{portfolioWithCommissionLinedata.length > 10 ?
							<LinechartComponent data={portfolioWithCommissionLinedata} levels={[]}
								events={[]}
							></LinechartComponent>
							: null
						}
					</TableBody>
				</Table>
			</TableContainer>
		</TableContainer>
	);
}
