import axios, { all } from "axios";
import { data } from "./avaChartData";

const API_URL = 'https://aiamo.anerdins-iot.se/'

export async function loginUser(username, password, pushToken, platform, version) {
	try {
		console.log("Calling login with: ")
		console.log(username)
		console.log(password)
		console.log(pushToken)
		const result = await axios.post(API_URL + 'api/auth/login', { username, password, pushToken, platform, version }, { validateStatus: () => true })

		console.log("result.data.message: ")
		console.log(result.data.message)
		console.log("result.data: ")
		console.log(result.data)

		var success = result.data.success

		if (success) {
			console.log("login successful")
			if (result.data.role !== "Admin") {
				console.log("Login unsuccessful, no admin")
				return { success: false, msg: "Ej auktoriserad som admin" }
			}
			try {
				//const cookie = String(String(result.request._lowerCaseResponseHeaders["set-cookie"]).split(';')[0]).split('=')[1];
				const cookie = String(result.data.token)
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookie;
				console.log(result)
				console.log(result.headers)
				//console.log("cookie: ", cookie)
				/*
				await storeLocalDataSecure(TOKEN_KEY, cookie)
				await storeLocalDataSecure(REFRESHTOKEN_KEY, result.data.refresh_token);
				await storeLocalDataSecure(USERNAME_KEY, username)
				await storeLocalDataSecure(ROLE_KEY, result.data.role)
				await storeLocalDataSecure(USERID_KEY, result.data.user);
				await storeLocalDataSecure(TOKEN_EXPIRE_KEY, JSON.stringify(Date.parse(new Date(+new Date() + 28800000))))
				*/
			} catch (e) {
				console.error(e)
			}
			return { success: true, msg: "Login successful: " + result.data.message, role: result.data.role, id: result.data.user, jwt: result.data.token, user: result.data.user }
		} else {
			console.log("Login unsuccessful")
			return { success: false, msg: result.data.message }
		}
	} catch (e) {
		console.error("loginUser error: ", e)
		return { success: false, msg: "Nätverksfel, försök igen" }
	}
}

export async function getAllTrades(funcSetter, filterParams = { limit: 4000, events: true }) {
	const io_url = API_URL + "api/trade/getTrades"
	const default_db = "759569532221653053"

	console.log("Fetching trades from: " + io_url + " with params: ")
	console.log(filterParams)

	try {
		let filterString = "?"
		for (const [key, value] of Object.entries(filterParams)) {
			filterString += key + "=" + value + "&"
		}
		filterString = filterString.slice(0, -1)
		console.log("fetching trades from:", String(io_url + filterString))
		console.log("with auth token: ", axios.defaults.headers.common['Authorization'])
		const result = await axios.get(String(io_url + filterString), { cache: false })

		var allInstruments;
		var allPositions = result.data.data;
		//console.log("1st position: ")
		//console.log(JSON.stringify(allPositions[50], null, 4))
		allPositions.sort((a, b) => (a.id < b.id) ? 1 : (a.id > b.id) ? -1 : 0);
		if (allPositions[0].id < allPositions[allPositions.length - 1].id) {
			console.log('id of first: ' + allPositions[0].id + ' is less than id of last: ' + allPositions[allPositions.length - 1].id + ', reversing');
			allPositions.reverse();
		}
		for (var i = 0; i < allPositions.length; i++) {
			allPositions[i].events?.reverse()
		}

		console.log("tot num trades fetched:")
		console.log(allPositions.length)

		if (funcSetter) {
			funcSetter(allPositions)
		}
		return allPositions;
	}
	catch (e) {
		console.warn("ERROR FETCHING TRADES: ")
		console.log(e)
		console.error("getAllTrades error: ", e)
	}
}

/**
 * Edit an event
 */
export async function editTrade(details) {
	const io_url = API_URL + "api/trade/edit"
	console.log("attempting to edit trade")
	console.log(details)
	try {
		const result = await axios.put(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("editTrade error: ", e)
	}
}

/**
 * Create a fill event
 */
export async function fillTrade(details) {
	const io_url = API_URL + "api/trade/fill"
	console.log("attempting to fill trade")
	console.log(details)
	try {
		const result = await axios.put(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("fillTrade error: ", e)
	}
}

/**
 * Creates a change event
 */
export async function changeTrade(details) {
	const io_url = API_URL + "api/trade/change"
	console.log("attempting to change trade")
	console.log(details)
	try {
		const result = await axios.put(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("changeTrade error: ", e)
	}
}

/**
 * Creates a close event
 */
export async function closeTrade(details) {
	const io_url = API_URL + "api/trade/close"
	console.log("attempting to close trade")
	console.log(details)
	try {
		const result = await axios.post(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("closeTrade error: ", e)
	}
}

/**
 * Changes the category of a trade
 */
export async function updateCategory(details) {
	const io_url = API_URL + "api/trade/updateCategory"
	console.log("attempting to update trade category")
	console.log(details)
	try {
		const result = await axios.post(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("updateCategory error: ", e)
	}
}

/**
 * Creates a new trade
 */
export async function newTrade(details) {
	const io_url = API_URL + "api/trade/new"
	console.log("attempting to create new trade")
	console.log(details)
	try {
		const result = await axios.post(io_url, details)
		console.log(result)
		return result
	} catch (e) {
		console.error("newTrade error: ", e)
	}
}

/**
 * Deletes all events and the trade itself
 */
export async function deleteTrade(id) {
	const io_url = API_URL + "api/trade/deleteTrade"
	console.log("attempting to delete trade")
	console.log(id)
	try {
		const result = await axios.delete(io_url + "/" + id)
		console.log(result)
		return result
	} catch (e) {
		console.error("deleteTrade error: ", e)
	}
}

/**
 * Deletes an event
 */
export async function deleteEvent(id) {
	const io_url = API_URL + "api/trade/deleteEvent"
	console.log("attempting to delete event")
	console.log(id)
	try {
		const result = await axios.delete(io_url + "/" + id)
		console.log(result)
		return result
	} catch (e) {
		console.error("deleteEvent error: ", e)
	}
}

/**
 * Searches for symbols
 */
export async function searchSymbols(searchString) {
	const io_url = API_URL + "api/trade/searchSymbols"
	console.log("attempting to search symbols")
	console.log(searchString)
	try {
		const result = await axios.get(io_url + "/" + searchString)
		console.log(result)
		if (result.data.success) {
			return result.data.data
		} else {
			return []
		}
	} catch (e) {
		console.error("searchSymbols error: ", e)
	}
}

/**
 * Gets live data and leverage for a specific symbol
 */
export async function productPriceLive(isin) {
	const io_url = API_URL + "api/ngm/instrumentDb" // TODO: Ska ändras till live, men just nu spammar den. "api/ngm/instrumentLive
	console.log("attempting to get product price live")
	console.log(isin)
	try {
		const result = await axios.get(io_url + "/" + isin)
		console.log(result)
		return result.data
	} catch (e) {
		console.error("instrumentLive error: ", e)
	}
}

/**
 * Gets price graph data
 */
export async function priceGraph(isin) {
	const io_url = API_URL + "api/ngm/priceGraph"
	console.log("attempting to get price graph")
	console.log(isin)
	try {
		const result = await axios.get(io_url + "/" + isin)
		console.log(result)
		return result.data
	} catch (e) {
		console.error("getPriceGraph error: ", e)
	}
}

/**
 * Follows specified trade by id
 */
export async function followTrade(id) {
	const io_url = API_URL + "api/trade/followTrade"
	console.log("attempting to follow trade")
	console.log(id)
	try {
		const result = await axios.post(io_url, { "tradeId": id })
		console.log(result)
		return result.status === 200
	} catch (e) {
		console.error("followTrade error: ", e)
	}
}

/**
 * Unfollows specified trade by id
 */
export async function unfollowTrade(id) {
	const io_url = API_URL + "api/trade/unfollowTrade"
	console.log("attempting to unfollow trade")
	console.log(id)
	try {
		const result = await axios.delete(io_url, { data: { "tradeId": id } })
		console.log(result)
		return result.status === 200
	} catch (e) {
		console.error("unfollowTrade error: ", e)
	}
}

/**
 * Gets all followed trades
 */
export async function getFollowedTrades() {
	const defaultFetchLimit = 500;
	const getEvents = true;
	const io_url = API_URL + "api/trade/getUserTrades" + "?limit=" + defaultFetchLimit + "&events=" + false;
	console.log("attempting to fetch getUserTrades")
	try {
		const result = await axios.get(io_url)
		var ids = []
		for (var i = 0; i < result.data.length; i++) {
			ids.push(result.data[i].id)
		}
		ids.sort((a, b) => (a.id < b.id) ? 1 : -1);
		return ids
	} catch (e) {
		console.error("getUserTradesIds error: ", e)
	}
}

export async function getAvanzaChartData(orderBookId, period, resolution, funcSetter) {
	//https://www.avanza.se/_api/price-chart/marketmaker/1217728?timePeriod=today
	const io_url = "https://www.avanza.se/_api/price-chart/marketmaker/" + orderBookId + "?timePeriod=" + period + "&resolution=" + resolution;
	console.log("attempting to fetch getAvanzaChartData")
	try {
		//const result = await axios.get(io_url)
		const result = { data: data };
		if (result.data.marketMaker) {
			var res = result.data.marketMaker;

			//Ställen där ingen trading skett, t.ex. natt och helg, så är buy=sell=null
			//Ta bort dessa datapunkter
			for (var i = 0; i < res.length; i++) {
				//Gör om timestamp till "yyyy-mm-dd"
				res[i].time = res[i].timestamp
				res[i].value = res[i].sell
			}
			res = res.filter((e) => e.buy != null || e.sell != null || e.value != null)
			res.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
			console.log(res)
			funcSetter(res)
			return res
		}
	} catch (e) {
		console.error("getAvanzaChartData error: ", e)
	}
}

export async function getAvanzaChartDataViaProxy(orderBookId, period, resolution, funcSetter) {
	//https://www.avanza.se/_api/price-chart/marketmaker/1217728?timePeriod=today
	const io_url = API_URL + "api/proxy/_api/price-chart/marketmaker/" + orderBookId + "?timePeriod=" + period + "&resolution=" + resolution;
	console.log("attempting to fetch getAvanzaChartData")
	try {
		const result = await axios.get(io_url)
		if (result.data.marketMaker) {
			var res = result.data.marketMaker;

			//Ställen där ingen trading skett, t.ex. natt och helg, så är buy=sell=null
			//Ta bort dessa datapunkter
			for (var i = 0; i < res.length; i++) {
				//Gör om timestamp till "yyyy-mm-dd"
				res[i].time = res[i].timestamp
				res[i].value = res[i].sell ?? res[i].buy ?? null;
			}
			res = res.filter((e) => e.buy != null)
			res = res.filter((e) => e.sell != null)
			res = res.filter((e) => e.value != null)
			res.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
			console.log(res)
			funcSetter(res)
			return res
		}
	} catch (e) {
		console.error("getAvanzaChartDataViaProxy error: ", e)
	}
}

export async function getLivePriceOnSearchedSymbol(searchString) {
	const io_url = API_URL + "api/trade/searchSymbols"
	console.log("attempting to search symbols")
	console.log(searchString)
	try {
		const result = await axios.get(io_url + "/" + searchString)
		console.log(result)
		if (result.data.success) {
			return result.data.data[0].price
		} else {
			return { bid: 0, ask: 0, spread: 0 }
		}
	} catch (e) {
		console.error("searchSymbols error: ", e)
	}
}