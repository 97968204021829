import { Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FilterContext, MenuContext, TradesContext } from "../App";
import { translateUserIdToName } from "../lib/helpFunc";
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';

export default function FilterMenuMobile(props) {
	const { tradeFilter, setTradeFilter, allInstruments, allTraders } = useContext(FilterContext);
	const { isFilterMenuOpen, setIsFilterMenuOpen, isNewTradeMenuOpen, setIsNewTradeMenuOpen } = useContext(MenuContext);


	const [filterHeaders, setFilterHeaders] = useState();
	const [filterInputs, setFilterInputs] = useState();

	const instruments = allInstruments;
	const names = allTraders;

	useEffect(() => {
		if (tradeFilter) {
			var headers = [];
			var inputs = [];
			for (const [key, value] of Object.entries(tradeFilter)) {
				headers.push(key);
				inputs.push(value);
			}
			console.log('headers', headers);
			console.log('inputs', inputs);
			setFilterHeaders(headers);
			setFilterInputs(inputs);
		}
	}, [tradeFilter]);

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Filter</TableCell>
						<TableCell sx={{ textAlign: "right" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => { setIsFilterMenuOpen(false); }}
							>
								<CloseIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell>
							Rensa
						</TableCell>
						<TableCell>
							<IconButton
								aria-label="clear filters"
								size="small"
								onClick={() => {
									setTradeFilter({
										"trader": {
											"type": "string",
											"value": []
										},
										"open": { "type": "boolean", "value": true },
										"closed": { "type": "boolean", "value": false },
										"ticker": {
											"type": "string",
											"value": []
										},
										"long": { "type": "boolean", "value": true },
										"short": { "type": "boolean", "value": true },
										"swing": { "type": "boolean", "value": true },
										"day": { "type": "boolean", "value": true },
									})
								}}
							>
								<ReplayIcon />
							</IconButton>
						</TableCell>
					</TableRow>
					{
						filterHeaders && filterHeaders.map((header, index) => {
							return <TableRow key={index}>
								<TableCell>
									{String(header).charAt(0).toUpperCase() + String(header).slice(1)}
								</TableCell>
								<TableCell>
									{
										filterInputs[index].type == "boolean" ?
											<Checkbox
												sx={{ paddingLeft: 0 }}
												checked={tradeFilter[header].value}
												onChange={(e) => {
													setTradeFilter({
														...tradeFilter,
														[header]: {
															...tradeFilter[header],
															value: e.target.checked
														}
													})
												}} />
											:
											filterInputs[index].type == "string" ?
												<FormControl sx={{ width: "160px" }} size="small">
													<InputLabel id={header + "label"}>{/*header*/}</InputLabel>
													<Select
														/*labelId={header + "label"}*/
														id={header}
														multiple
														value={tradeFilter[header].value}
														onChange={(e) => {
															setTradeFilter({
																...tradeFilter,
																[header]: {
																	...tradeFilter[header],
																	value: e.target.value
																}
															})
														}}
														input={<OutlinedInput /*label={header}*/ />}
														displayEmpty
														renderValue={(selected) => {
															//if no filter is selected, display "All"
															if (selected.length == 0) {
																return ("Allting")
															}
															if (selected.length > 3) {
																return (selected.length + " valda")
															}
															if (header == "trader") {
																selected = selected.map((id) => {
																	return (id.name)
																})
															}
															return (selected.join(', '))
														}}
													>
														{
															header == "trader" ? names.map((name) => {
																return (<MenuItem key={name.id} value={name}>
																	<Checkbox checked={tradeFilter[header].value.some(obj => obj.id == name.id)} />
																	<ListItemText primary={(name.name)} />
																</MenuItem>)
															})
																:
																header == "ticker" ? instruments.map((instrument) => {
																	return (<MenuItem key={instrument} value={instrument}>
																		<Checkbox checked={tradeFilter[header].value.indexOf(instrument) > -1} />
																		<ListItemText primary={instrument} />
																	</MenuItem>)
																})
																	:
																	null
														}
													</Select>
												</FormControl>
												:
												<TableCell key={index}>{String("-")}</TableCell>
									}
								</TableCell>
							</TableRow>
						})
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
}