import { Button, Checkbox, FormControl, Hidden, IconButton, Input, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MenuContext, TradesContext } from "../App";
import { convertDateToRelevant, isNumericString, translateCategoryToDaySwing, translateStatusToText, translateTypeToText, translateUserIdToName } from "../lib/helpFunc";
import { changeTrade, closeTrade, deleteEvent, deleteTrade, editTrade, fillTrade, getAvanzaChartDataViaProxy } from "../lib/network";

const handleEditEvent = async (values, comment, selectedEvent, setSelectedEvent, setIsEditEventMenuOpen, setSelectedTrade, trades) => {
	console.log("attempting to create update with values:")
	console.log({ values, comment })
	try {
		const errorValues = [];

		if (values.price && !isNumericString(values.price)) {
			errorValues.push("Pris")
		}
		if (values.size && !isNumericString(values.size)) {
			errorValues.push("Storlek")
		}
		if (values.sl && !isNumericString(values.sl)) {
			errorValues.push("TP1")
		}
		if (values.tp1 && !isNumericString(values.tp1)) {
			errorValues.push("TP1")
		}
		if (values.tp2 && !isNumericString(values.tp2)) {
			errorValues.push("TP2")
		}
		if (values.tp3 && !isNumericString(values.tp3)) {
			errorValues.push("TP3")
		}

		if (errorValues.length > 0) {
			alert("Följande fält innehåller felaktiga värden, ex. bokstäver: \n" + errorValues.join("\n"))
			return
		}

		const changeObj = {
			"id": selectedEvent.id,
			"price": values.price ? String(values.price).replaceAll(',', '.') : null,
			"size": values.size ? String(values.size).replaceAll(',', '.') : null,
			"sl": values.sl ? String(values.sl).replaceAll(',', '.') : null,
			"tp1": values.tp1 ? String(values.tp1).replaceAll(',', '.') : null,
			"tp2": values.tp2 ? String(values.tp2).replaceAll(',', '.') : null,
			"tp3": values.tp3 ? String(values.tp3).replaceAll(',', '.') : null,
			"info": comment ? comment : null,
		};

		for (const [key, value] of Object.entries(changeObj)) {
			if (value === null) {
				delete changeObj[key]
			}
		}

		if (Object.entries(changeObj).length === 0) {
			alert("Inga ändringar gjorda")
			return
		}

		const result = await editTrade(changeObj)
		console.log(result)
		if (result.status === 200) {
			setSelectedEvent(null)
			setIsEditEventMenuOpen(false)
			alert("Uppdatering lyckades")
			console.log("success")
			//setSelectedTrade(trades.find(trade => trade.id === selectedEvent.trade_id));
		} else {
			alert("Något gick fel, kontakta admin")
		}
	} catch (e) {
		console.error(e)
	}
}

//  ███████ ██    ██ ███    ██  ██████ 
//  ██      ██    ██ ████   ██ ██      
//  █████   ██    ██ ██ ██  ██ ██      
//  ██      ██    ██ ██  ██ ██ ██      
//  ██       ██████  ██   ████  ██████ 

export default function EditEventMenuMobile(props) {
	const [tableHeight, setTableHeight] = useState(400); // Default height
	const { isFilterMenuOpen, setIsFilterMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen } = useContext(MenuContext);
	const { trades, selectedEvent, setSelectedEvent, selectedTrade, setSelectedTrade } = useContext(TradesContext);

	const [updatedValues, setUpdatedValues] = useState({
		sl: null,
		tp1: null,
		tp2: null,
		tp3: null,
	});
	const [updatedComment, setUpdatedComment] = useState("");

	useEffect(() => {
		// Calculate available height between header and footer
		const headerHeight = document.getElementById('header').offsetHeight;
		var filterMenuHeight = 0;
		if (isFilterMenuOpen) {
			filterMenuHeight = 68; //document.getElementById('filter-menu').offsetHeight; //TODO: dumt system, men funkar, hitta height på filtermenyn manuellt
		}
		const availableHeightTmp = window.innerHeight - headerHeight - filterMenuHeight;

		// Set the height of the table dynamically
		setTableHeight(availableHeightTmp);
	}, [isFilterMenuOpen]);

	const detailRows1 = [
		{ title: "Datum", value: convertDateToRelevant(selectedEvent?.timestamp) },
		{ title: "Typ", value: translateTypeToText(selectedEvent?.type) },
		{ title: "Tillhör trade", value: selectedEvent?.trade_id },
		{ title: "Storlek", value: selectedEvent?.type == "close" ? (selectedEvent?.size) * -1 : selectedEvent?.size },
		{ title: "Vinst", value: selectedEvent?.net_profit },
		{ title: "Pris", value: selectedEvent?.price },
		{ title: "SL", value: selectedEvent?.sl },
		{ title: "TP1", value: selectedEvent?.tp1 },
		{ title: "TP2", value: selectedEvent?.tp2 },
		{ title: "TP3", value: selectedEvent?.tp3 },
		{ title: "Kommentar", value: selectedEvent?.info },
	];

	return (
		<TableContainer sx={{ width: "100%", maxHeight: tableHeight - 4, scrollbarWidth: "thin" }} className="small-scrollbar">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={7} sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => { setSelectedEvent(null); setIsEditEventMenuOpen(false); setSelectedTrade(trades.find(trade => trade.id === selectedEvent.trade_id)) }}
							>
								<ChevronLeftIcon />
							</IconButton>
							Händelse {selectedEvent?.id} - {translateTypeToText(selectedEvent?.type)}
						</TableCell>
						<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
							<IconButton
								aria-label="modify row"
								size="small"
								onClick={() => { setSelectedEvent(null); setIsEditEventMenuOpen(false) }}
							>
								<CloseIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
			<TableContainer sx={{ flex: 1, display: "flex" }}>
				<Table sx={{ flex: 1 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2} sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								Detaljer
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							detailRows1.map((row, index) => {
								return (
									<TableRow>
										<TableCell sx={{ borderRight: "1px solid #515151", textAlign: "right", width: "35%" }}>
											{row.title}
										</TableCell>
										<TableCell>
											{row.value}
										</TableCell>
									</TableRow>
								)
							})
						}
					</TableBody>
					<TableHead>
						<TableRow>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)", width: "45%" }}>
								Redigera händelse
							</TableCell>
							<TableCell sx={{ backgroundColor: "rgb(27, 30, 38)" }}>
								<FormControl fullWidth>
									<Button
										variant="contained"
										disableElevation
										size="small"
										endIcon={<SendIcon />}
										onClick={() => { handleEditEvent(updatedValues, updatedComment, selectedEvent, setSelectedEvent, setIsEditEventMenuOpen, setSelectedTrade, trades) }}
									>
										Uppdatera
									</Button>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								Pris:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.price ?? "Pris"}
									value={updatedValues.price}
									onChange={(e) => setUpdatedValues({ ...updatedValues, price: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								Size:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.type == "close" ? (selectedEvent?.size) * -1 : selectedEvent?.size ?? "Size"}
									value={updatedValues.size}
									onChange={(e) => setUpdatedValues({ ...updatedValues, size: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								SL:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.sl ?? "SL"}
									value={updatedValues.sl}
									onChange={(e) => setUpdatedValues({ ...updatedValues, sl: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP1:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.tp1 ?? "TP1"}
									value={updatedValues.tp1}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp1: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP2:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.tp2 ?? "TP2"}
									value={updatedValues.tp2}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp2: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								TP3:
							</TableCell>
							<TableCell style={{ height: 20 }}>
								<Input
									fullWidth
									sx={{ fontSize: "0.875rem", height: 19 }}
									placeholder={selectedEvent?.tp3 ?? "TP3"}
									value={updatedValues.tp3}
									onChange={(e) => setUpdatedValues({ ...updatedValues, tp3: e.target.value })}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} rowSpan={3} style={{ padding: 0, margin: 0 }}>
								<FormControl fullWidth>
									<TextField
										id="outlined-multiline-static"
										margin="none"
										multiline
										rows={3}
										placeholder="Kommentar"
										value={updatedComment}
										onChange={(e) => setUpdatedComment(e.target.value)}
									/>
								</FormControl>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</TableContainer>
	);
}
