import { Box, Button, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useContext, useEffect, useState } from "react";
import { convertDateToRelevant, convertDayToRecent, dateDifference, translateCategoryToDaySwing, translateStatusToText, translateTypeToText, translateUserIdToName } from "../lib/helpFunc";
import { MenuContext, TradesContext } from "../App";

export default function TradeRowMobile(props) {
	const [isExpanded, setIsExpanded] = useState(false);
	const { selectedTrade, setSelectedTrade, selectedEvent, setSelectedEvent } = useContext(TradesContext);
	const { isNewTradeMenuOpen, setIsNewTradeMenuOpen, isEditEventMenuOpen, setIsEditEventMenuOpen, isStatisticsMenuOpen, setIsStatisticsMenuOpen } = useContext(MenuContext);

	const trade = props.trade;

	if (!trade) {
		return <></>;
	}

	return (
		<>
			<TableRow key={trade.id} style={{ backgroundColor: trade.status == "open" ? "#262a35" : "#1e222d", boxShadow: isExpanded ? "1px 1px 0 0 var(--w2) inset, -1px 0 0 0 var(--w2) inset" : "none" }}>
				<TableCell onClick={() => setIsExpanded((prev) => !prev)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{convertDateToRelevant(trade.events[trade.events.length - 1].timestamp)}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.user?.username}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell onClick={() => setIsExpanded((prev) => !prev)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{trade.size_left}/{trade.size}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>x{trade.leverage}&nbsp;</p>
					</div>
				</TableCell>
				<TableCell colSpan={3} onClick={() => setIsExpanded((prev) => !prev)} style={{ border: "none" }}>
					<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
						<p style={{ padding: 0, margin: 0 }}>{trade.ticker}&nbsp;</p>
						<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{translateCategoryToDaySwing(trade.category)}trade&nbsp;</p>
					</div>
				</TableCell>
			</TableRow >
			<TableRow key={trade.id + "z"} style={{ backgroundColor: trade.status == "open" ? "#262a35" : "#1e222d", boxShadow: isExpanded ? "0 -1px 0 1px var(--w2) inset" : "none" }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={isExpanded} timeout="auto" unmountOnExit>

						<TableRow style={{ backgroundColor: trade.status == "open" ? "#262a35" : "#1e222d" }}>
							<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none", paddingLeft: 0 }}>
								<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
									<p style={{ color: "var(--w2)", fontSize: 12, padding: 0, margin: 0, marginTop: 4, lineHeight: "0.8rem", fontWeight: 200 }}>Id</p>
									<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.id}&nbsp;</p>
								</div>
							</TableCell>
							<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none", paddingLeft: 0 }}>
								<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
									<p style={{ color: "var(--w2)", fontSize: 12, padding: 0, margin: 0, marginTop: 4, lineHeight: "0.8rem", fontWeight: 200 }}>Namn</p>
									<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.name}&nbsp;</p>
								</div>
							</TableCell>
							<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none", paddingLeft: 0 }}>
								<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
									<p style={{ color: "var(--w2)", fontSize: 12, padding: 0, margin: 0, marginTop: 4, lineHeight: "0.8rem", fontWeight: 200 }}>Nr. event</p>
									<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{trade.events?.length}&nbsp;</p>
								</div>
							</TableCell>
							<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none", paddingLeft: 0 }}>
								<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
									<p style={{ color: "var(--w2)", fontSize: 12, padding: 0, margin: 0, marginTop: 4, lineHeight: "0.8rem", fontWeight: 200 }}>Öppettid</p>
									<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{dateDifference(trade.events[trade.events.length - 1].timestamp, trade.status == "closed" ? trade.events[0].timestamp : new Date())}&nbsp;</p>
								</div>
							</TableCell>
							<TableCell onClick={() => setIsExpanded(true)} style={{ border: "none", paddingLeft: 0 }}>
								<div style={{ flexDirection: "column", display: "flex", lineHeight: "1rem" }}>
									<p style={{ color: "var(--w2)", fontSize: 12, padding: 0, margin: 0, marginTop: 4, lineHeight: "0.8rem", fontWeight: 200 }}>Gain</p>
									<p style={{ color: "var(--w3)", fontSize: 10, padding: 0, margin: 0, lineHeight: "0.8rem", fontWeight: 200 }}>{"-%"}&nbsp;</p>
								</div>
							</TableCell>
						</TableRow >

						<Box sx={{ margin: 1 }}>
							<div style={{ display: "flex", flexDirection: "row-reverse" }}>
								<TableCell onClick={() => { setSelectedTrade(trade); setIsNewTradeMenuOpen(false); setIsEditEventMenuOpen(false); setSelectedEvent(false); setIsStatisticsMenuOpen(false); }} style={{ border: "none" }}>
									<IconButton
										aria-label="modify row"
										size="small">
										<><Typography>Redigera trade</Typography> <ChevronRightIcon /></>
									</IconButton>
								</TableCell>
							</div>
						</Box>

					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}