function parseISOString(s) {
	var b = s.split(/\D+/);
	return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function convertDateToRelevant(date) {
	try {
		const today = new Date();
		const yesterday = new Date();
		yesterday.setDate(today.getDate() - 1);
		const parsed = parseISOString(date);
		const dateObj = new Date(parsed);
		if (dateObj.toLocaleDateString() === today.toLocaleDateString()) {
			return String(dateObj.toLocaleTimeString()).slice(0, -3);
		} else if (dateObj.toLocaleDateString() === yesterday.toLocaleDateString()) {
			return "Igår" + " " + String(dateObj.toLocaleTimeString()).slice(0, -3);
		} else {
			return dateObj.toLocaleDateString();
		}
	} catch (e) {
		return date;
	}
}

export function convertDayToRecent(date) {
	//converts date to "i onsdags 14:23" och "måndag förra veckan"
	const tradeDate = new Date(date);
	const retDate = tradeDate.toLocaleDateString('sv-SE', { weekday: 'long' });
	//if yesterday
	const today = new Date();
	const yesterday = new Date();
	yesterday.setDate(today.getDate() - 1);
	if (tradeDate.toLocaleDateString() === today.toLocaleDateString()) {
		return tradeDate.toLocaleTimeString();
	}
	if (tradeDate.toLocaleDateString() === yesterday.toLocaleDateString()) {
		return tradeDate.toLocaleTimeString();
	}
	//if at any point during the last 7 days
	const startOfThisWeek = new Date();
	startOfThisWeek.setDate(today.getDate() - today.getDay());
	const startOfLastWeek = new Date();
	startOfLastWeek.setDate(startOfThisWeek.getDate() - 7);
	const startOfTwoWeeksAgo = new Date();
	startOfTwoWeeksAgo.setDate(startOfLastWeek.getDate() - 7);
	const startOfThisMonth = new Date();
	startOfThisMonth.setDate(1);
	const startOfLastMonth = new Date();
	startOfLastMonth.setMonth(startOfThisMonth.getMonth() - 1);
	const startOfThisYear = new Date();
	startOfThisYear.setMonth(0);
	startOfThisYear.setDate(1);
	const startOfLastYear = new Date();
	startOfLastYear.setFullYear(startOfThisYear.getFullYear() - 1);
	startOfLastYear.setMonth(0);
	startOfLastYear.setDate(1);

	if (tradeDate >= startOfThisWeek) {
		return retDate + "s " + tradeDate.toLocaleTimeString().slice(0, -3);
	} else if (tradeDate >= startOfLastWeek) {
		return "Förra " + retDate + "en";
	} else if (tradeDate >= startOfTwoWeeksAgo) {
		return retDate + " 2v sedan";
	} else if (tradeDate >= startOfThisMonth) {
		return "Denna månad";
	} else if (tradeDate >= startOfLastMonth) {
		return "Förra månaden";
	} else if (tradeDate >= startOfThisYear) {
		return "Detta år";
	} else if (tradeDate >= startOfLastYear) {
		return "Förra året";
	} else {
		return " ";
	}
}

export function dateDifference(date1, date2) {
	const tmpDate1 = new Date(date1);
	const tmpDate2 = new Date(date2); // Current date and time
	// Calculate the difference in milliseconds
	const differenceInMilliseconds = tmpDate2 - tmpDate1;

	// Convert the difference to days, hours, minutes, etc.
	const millisecondsInOneDay = 24 * 60 * 60 * 1000;
	const daysDifference = Math.floor(differenceInMilliseconds / millisecondsInOneDay);
	const hoursDifference = Math.floor((differenceInMilliseconds % millisecondsInOneDay) / (60 * 60 * 1000));
	const minutesDifference = Math.floor((differenceInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
	const secondsDifference = Math.floor((differenceInMilliseconds % (60 * 1000)) / 1000);


	if (daysDifference > 2) {
		return daysDifference + " dagar";
	}
	if (daysDifference > 0) {
		return daysDifference + " dagar " + hoursDifference + " h";
	}
	if (hoursDifference > 0) {
		return hoursDifference + " h " + minutesDifference + " m";
	}
	if (minutesDifference > 0) {
		return minutesDifference + " m " + secondsDifference + " s";
	}
	return secondsDifference + " s";
}

export function translateUserIdToName(id) {
	switch (id) {
		case "cff7e4f5-e563-4d5b-9680-2243b326e09b":
			return "Sammie";
		case "41fe9ef9-96a3-43ee-877b-39ad8c5249c9":
			return "Adam";
		default:
			return "0";
	}
}

export function translateUserNameToId(name) {
	switch (name) {
		case "1":
			return "cff7e4f5-e563-4d5b-9680-2243b326e09b";
		case "2":
			return "41fe9ef9-96a3-43ee-877b-39ad8c5249c9";
		default:
			return "0";
	}
}

export function translateCategoryToDaySwing(category) {
	if (category.includes("day")) {
		return "Day";
	} else if (category.includes("swing")) {
		return "Swing";
	} else {
		return "unknown";
	}
}

export function translateStatusToColor(status) {
	switch (status) {
		case "open":
			return "#2ecc71";
		case "close":
			return "#e74c3c";
		case "change":
			return "#3498db";
		default:
			return "#f1c40f";
	}
}

export function translateStatusToText(status) {
	switch (status) {
		case "open":
			return "Öppen";
		case "closed":
			return "Stängd";
		default:
			return "Okänd";
	}
}

export function translateTypeToText(type) {
	switch (type) {
		case "open":
			return "Öppning";
		case "fill":
			return "Fyllning";
		case "close":
			return "Sälj";
		case "change":
			return "Ändring";
		default:
			return "Okänd";
	}
}

export function isNumericString(str) {
	return /^\d*\.?\d*$|^\d*,?\d*$/.test(str);
};