export const data = {
	"ohlc":
		[
			{ "timestamp": 1706173200000, "open": 21.1, "close": 21.01, "low": 21.01, "high": 21.1, "totalVolumeTraded": 321 },
			{ "timestamp": 1706173800000, "open": 21.08, "close": 21.04, "low": 21.04, "high": 21.08, "totalVolumeTraded": 1900 },
			{ "timestamp": 1706174400000, "open": 20.97, "close": 20.87, "low": 20.87, "high": 20.97, "totalVolumeTraded": 300 },
			{ "timestamp": 1706175000000, "open": 21.04, "close": 21.04, "low": 21.04, "high": 21.04, "totalVolumeTraded": 9 },
			{ "timestamp": 1706176200000, "open": 21.07, "close": 21.07, "low": 21.07, "high": 21.07, "totalVolumeTraded": 949 },
			{ "timestamp": 1706177400000, "open": 21.03, "close": 21.03, "low": 21.03, "high": 21.03, "totalVolumeTraded": 23 },
			{ "timestamp": 1706185800000, "open": 21.05, "close": 21.05, "low": 21.05, "high": 21.05, "totalVolumeTraded": 400 },
			{ "timestamp": 1706188200000, "open": 20.81, "close": 20.81, "low": 20.81, "high": 20.81, "totalVolumeTraded": 60 },
			{ "timestamp": 1706188800000, "open": 20.72, "close": 20.72, "low": 20.72, "high": 20.72, "totalVolumeTraded": 1188 },
			{ "timestamp": 1706190000000, "open": 20.9, "close": 20.84, "low": 20.84, "high": 20.9, "totalVolumeTraded": 1188 },
			{ "timestamp": 1706205600000, "open": 22.14, "close": 22.14, "low": 22.14, "high": 22.14, "totalVolumeTraded": 189 },
			{ "timestamp": 1706208000000, "open": 22.16, "close": 22.16, "low": 22.16, "high": 22.16, "totalVolumeTraded": 100 },
			{ "timestamp": 1706208600000, "open": 22.17, "close": 22.17, "low": 22.17, "high": 22.17, "totalVolumeTraded": 9 },
			{ "timestamp": 1706271600000, "open": 21.59, "close": 21.59, "low": 21.59, "high": 21.59, "totalVolumeTraded": 300 }
		],
	"metadata":
	{
		"resolution":
		{
			"chartResolution": "ten_minutes",
			"availableResolutions":
				["ten_minutes", "thirty_minutes", "hour", "day"]
		}
	},
	"from": "2024-01-25",
	"to": "2024-01-26",
	"marketMaker":
		[
			{ "buy": 24.7000, "sell": 25.2000, "timestamp": 1705647600000 },
			{ "buy": 24.7000, "sell": 25.2000, "timestamp": 1705648200000 },
			{ "buy": 24.6900, "sell": 25.1900, "timestamp": 1705648800000 },
			{ "buy": 24.6900, "sell": 25.1900, "timestamp": 1705649400000 },
			{ "buy": 24.6800, "sell": 25.1800, "timestamp": 1705650000000 },
			{ "buy": 24.7000, "sell": 25.2000, "timestamp": 1705650600000 },
			{ "buy": 24.7000, "sell": 25.2000, "timestamp": 1705651200000 },
			{ "buy": 24.7100, "sell": 25.2100, "timestamp": 1705651800000 },
			{ "buy": 25.0000, "sell": 25.5000, "timestamp": 1705652400000 },
			{ "buy": 24.8900, "sell": 24.9900, "timestamp": 1705653000000 },
			{ "buy": 25.0900, "sell": 25.1900, "timestamp": 1705653600000 },
			{ "buy": 24.9200, "sell": 25.0200, "timestamp": 1705654200000 },
			{ "buy": 24.8400, "sell": 24.9400, "timestamp": 1705654800000 },
			{ "buy": 24.7400, "sell": 24.8400, "timestamp": 1705655400000 },
			{ "buy": 24.7900, "sell": 24.8900, "timestamp": 1705656000000 },
			{ "buy": 24.8800, "sell": 24.9800, "timestamp": 1705656600000 },
			{ "buy": 24.7800, "sell": 24.8800, "timestamp": 1705657200000 },
			{ "buy": 24.6700, "sell": 24.7700, "timestamp": 1705657800000 },
			{ "buy": 24.6500, "sell": 24.7500, "timestamp": 1705658400000 },
			{ "buy": 24.5700, "sell": 24.6700, "timestamp": 1705659000000 },
			{ "buy": 24.6600, "sell": 24.7600, "timestamp": 1705659600000 },
			{ "buy": 24.6800, "sell": 24.7800, "timestamp": 1705660200000 },
			{ "buy": 24.7700, "sell": 24.8700, "timestamp": 1705660800000 },
			{ "buy": 24.8500, "sell": 24.9500, "timestamp": 1705661400000 },
			{ "buy": 24.9500, "sell": 25.0500, "timestamp": 1705662000000 },
			{ "buy": 24.9200, "sell": 25.0200, "timestamp": 1705662600000 },
			{ "buy": 24.8000, "sell": 24.9000, "timestamp": 1705663200000 },
			{ "buy": 24.8300, "sell": 24.9300, "timestamp": 1705663800000 },
			{ "buy": 24.9700, "sell": 25.0700, "timestamp": 1705664400000 },
			{ "buy": 24.9300, "sell": 25.0300, "timestamp": 1705665000000 },
			{ "buy": 24.8300, "sell": 24.9300, "timestamp": 1705665600000 },
			{ "buy": 24.7000, "sell": 24.8000, "timestamp": 1705666200000 },
			{ "buy": 24.8000, "sell": 24.9000, "timestamp": 1705666800000 },
			{ "buy": 24.9000, "sell": 25.0000, "timestamp": 1705667400000 },
			{ "buy": 25.0000, "sell": 25.1000, "timestamp": 1705668000000 },
			{ "buy": 24.9600, "sell": 25.0600, "timestamp": 1705668600000 },
			{ "buy": 24.6700, "sell": 24.7700, "timestamp": 1705669200000 },
			{ "buy": 24.4000, "sell": 24.5000, "timestamp": 1705669800000 },
			{ "buy": 23.8500, "sell": 23.9500, "timestamp": 1705670400000 },
			{ "buy": 23.5600, "sell": 23.6600, "timestamp": 1705671000000 },
			{ "buy": 23.8400, "sell": 23.9400, "timestamp": 1705671600000 },
			{ "buy": 23.7500, "sell": 23.8500, "timestamp": 1705672200000 },
			{ "buy": 23.5200, "sell": 23.6200, "timestamp": 1705672800000 },
			{ "buy": 23.7300, "sell": 23.8300, "timestamp": 1705673400000 },
			{ "buy": 23.5400, "sell": 23.6400, "timestamp": 1705674000000 },
			{ "buy": 23.6900, "sell": 23.7900, "timestamp": 1705674600000 },
			{ "buy": 23.2800, "sell": 23.3800, "timestamp": 1705675200000 },
			{ "buy": 23.4700, "sell": 23.5700, "timestamp": 1705675800000 },
			{ "buy": 23.2400, "sell": 23.3400, "timestamp": 1705676400000 },
			{ "buy": 23.0600, "sell": 23.1600, "timestamp": 1705677000000 },
			{ "buy": 23.3000, "sell": 23.4000, "timestamp": 1705677600000 },
			{ "buy": 23.3600, "sell": 23.4600, "timestamp": 1705678200000 },
			{ "buy": 23.6500, "sell": 23.7500, "timestamp": 1705678800000 },
			{ "buy": 23.2900, "sell": 23.3900, "timestamp": 1705679400000 },
			{ "buy": 23.0700, "sell": 23.1700, "timestamp": 1705680000000 },
			{ "buy": 23.3400, "sell": 23.4400, "timestamp": 1705680600000 },
			{ "buy": 23.8500, "sell": 23.9500, "timestamp": 1705681200000 },
			{ "buy": 23.6200, "sell": 23.7200, "timestamp": 1705681800000 },
			{ "buy": 23.5800, "sell": 23.6800, "timestamp": 1705682400000 },
			{ "buy": 23.5600, "sell": 23.6600, "timestamp": 1705683000000 },
			{ "buy": 23.5700, "sell": 23.6700, "timestamp": 1705683600000 },
			{ "buy": 23.6400, "sell": 23.7400, "timestamp": 1705684200000 },
			{ "buy": 23.7300, "sell": 23.8300, "timestamp": 1705684800000 },
			{ "buy": 23.8200, "sell": 23.9200, "timestamp": 1705685400000 },
			{ "buy": 23.6700, "sell": 23.7700, "timestamp": 1705686000000 },
			{ "buy": 23.5500, "sell": 23.6400, "timestamp": 1705686600000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705687200000 },
			{ "buy": 23.5500, "sell": 23.6400, "timestamp": 1705687800000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705688400000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705689000000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705689600000 },
			{ "buy": 23.5800, "sell": 23.6700, "timestamp": 1705690200000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705690800000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705691400000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705692000000 },
			{ "buy": 23.5800, "sell": 23.6700, "timestamp": 1705692600000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705693200000 },
			{ "buy": 23.5800, "sell": 23.6700, "timestamp": 1705693800000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705694400000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705695000000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705695600000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705696200000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705696800000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705697400000 },
			{ "buy": null, "sell": null, "timestamp": 1705698000000 },
			{ "buy": 23.3700, "sell": 23.8200, "timestamp": 1705906800000 },
			{ "buy": 23.3400, "sell": 23.7900, "timestamp": 1705907400000 },
			{ "buy": 23.3500, "sell": 23.8000, "timestamp": 1705908000000 },
			{ "buy": 23.3800, "sell": 23.8300, "timestamp": 1705908600000 },
			{ "buy": 23.3900, "sell": 23.8400, "timestamp": 1705909200000 },
			{ "buy": 23.4000, "sell": 23.8500, "timestamp": 1705909800000 },
			{ "buy": 23.4200, "sell": 23.8700, "timestamp": 1705910400000 },
			{ "buy": 23.3700, "sell": 23.8200, "timestamp": 1705911000000 },
			{ "buy": 23.4500, "sell": 23.9000, "timestamp": 1705911600000 },
			{ "buy": 23.5400, "sell": 23.6300, "timestamp": 1705912200000 },
			{ "buy": 23.5400, "sell": 23.6300, "timestamp": 1705912800000 },
			{ "buy": 23.3400, "sell": 23.4300, "timestamp": 1705913400000 },
			{ "buy": 23.2700, "sell": 23.3600, "timestamp": 1705914000000 },
			{ "buy": 23.2400, "sell": 23.3300, "timestamp": 1705914600000 },
			{ "buy": 23.4800, "sell": 23.5700, "timestamp": 1705915200000 },
			{ "buy": 23.1700, "sell": 23.2600, "timestamp": 1705915800000 },
			{ "buy": 23.1100, "sell": 23.2000, "timestamp": 1705916400000 },
			{ "buy": 23.0000, "sell": 23.0900, "timestamp": 1705917000000 },
			{ "buy": 23.0300, "sell": 23.1200, "timestamp": 1705917600000 },
			{ "buy": 23.0300, "sell": 23.1200, "timestamp": 1705918200000 },
			{ "buy": 23.1400, "sell": 23.2300, "timestamp": 1705918800000 },
			{ "buy": 23.1200, "sell": 23.2100, "timestamp": 1705919400000 },
			{ "buy": 23.4700, "sell": 23.5600, "timestamp": 1705920000000 },
			{ "buy": 23.6100, "sell": 23.7000, "timestamp": 1705920600000 },
			{ "buy": 23.6800, "sell": 23.7700, "timestamp": 1705921200000 },
			{ "buy": 23.7000, "sell": 23.7900, "timestamp": 1705921800000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705922400000 },
			{ "buy": 23.4600, "sell": 23.5500, "timestamp": 1705923000000 },
			{ "buy": 23.3400, "sell": 23.4300, "timestamp": 1705923600000 },
			{ "buy": 23.5200, "sell": 23.6100, "timestamp": 1705924200000 },
			{ "buy": 23.4400, "sell": 23.5300, "timestamp": 1705924800000 },
			{ "buy": 23.4500, "sell": 23.5400, "timestamp": 1705925400000 },
			{ "buy": 23.5100, "sell": 23.6000, "timestamp": 1705926000000 },
			{ "buy": 23.5100, "sell": 23.6000, "timestamp": 1705926600000 },
			{ "buy": 23.6300, "sell": 23.7200, "timestamp": 1705927200000 },
			{ "buy": 23.5300, "sell": 23.6200, "timestamp": 1705927800000 },
			{ "buy": 23.7800, "sell": 23.8700, "timestamp": 1705928400000 },
			{ "buy": 23.5800, "sell": 23.6700, "timestamp": 1705929000000 },
			{ "buy": 23.4600, "sell": 23.5500, "timestamp": 1705929600000 },
			{ "buy": 23.4700, "sell": 23.5600, "timestamp": 1705930200000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705930800000 },
			{ "buy": 23.3000, "sell": 23.3900, "timestamp": 1705931400000 },
			{ "buy": 23.5200, "sell": 23.6100, "timestamp": 1705932000000 },
			{ "buy": 23.5500, "sell": 23.6400, "timestamp": 1705932600000 },
			{ "buy": 23.6100, "sell": 23.7000, "timestamp": 1705933200000 },
			{ "buy": 23.6000, "sell": 23.6900, "timestamp": 1705933800000 },
			{ "buy": 23.4300, "sell": 23.5200, "timestamp": 1705934400000 },
			{ "buy": 23.4800, "sell": 23.5700, "timestamp": 1705935000000 },
			{ "buy": 23.5900, "sell": 23.6800, "timestamp": 1705935600000 },
			{ "buy": 23.6300, "sell": 23.7200, "timestamp": 1705936200000 },
			{ "buy": 23.4000, "sell": 23.4900, "timestamp": 1705936800000 },
			{ "buy": 23.4800, "sell": 23.5700, "timestamp": 1705937400000 },
			{ "buy": 23.6500, "sell": 23.7400, "timestamp": 1705938000000 },
			{ "buy": 23.5700, "sell": 23.6600, "timestamp": 1705938600000 },
			{ "buy": 23.6600, "sell": 23.7500, "timestamp": 1705939200000 },
			{ "buy": 23.5600, "sell": 23.6500, "timestamp": 1705939800000 },
			{ "buy": 23.4400, "sell": 23.5300, "timestamp": 1705940400000 },
			{ "buy": 23.4500, "sell": 23.5400, "timestamp": 1705941000000 },
			{ "buy": 23.4500, "sell": 23.5400, "timestamp": 1705941600000 },
			{ "buy": 23.2900, "sell": 23.3800, "timestamp": 1705942200000 },
			{ "buy": 23.3400, "sell": 23.4300, "timestamp": 1705942800000 },
			{ "buy": 23.3800, "sell": 23.4700, "timestamp": 1705943400000 },
			{ "buy": 23.4800, "sell": 23.5700, "timestamp": 1705944000000 },
			{ "buy": 23.7500, "sell": 23.8400, "timestamp": 1705944600000 },
			{ "buy": 23.6600, "sell": 23.7500, "timestamp": 1705945200000 },
			{ "buy": 23.7000, "sell": 23.8000, "timestamp": 1705945800000 },
			{ "buy": 23.7300, "sell": 23.8300, "timestamp": 1705946400000 },
			{ "buy": 23.7300, "sell": 23.8300, "timestamp": 1705947000000 },
			{ "buy": 23.7700, "sell": 23.8700, "timestamp": 1705947600000 },
			{ "buy": 23.7600, "sell": 23.8600, "timestamp": 1705948200000 },
			{ "buy": 23.7700, "sell": 23.8700, "timestamp": 1705948800000 },
			{ "buy": 23.7500, "sell": 23.8500, "timestamp": 1705949400000 },
			{ "buy": 23.7500, "sell": 23.8500, "timestamp": 1705950000000 },
			{ "buy": 23.7700, "sell": 23.8700, "timestamp": 1705950600000 },
			{ "buy": 23.7800, "sell": 23.8800, "timestamp": 1705951200000 },
			{ "buy": 23.7700, "sell": 23.8700, "timestamp": 1705951800000 },
			{ "buy": 23.7800, "sell": 23.8800, "timestamp": 1705952400000 },
			{ "buy": 23.7900, "sell": 23.8900, "timestamp": 1705953000000 },
			{ "buy": 23.8000, "sell": 23.9000, "timestamp": 1705953600000 },
			{ "buy": 23.8000, "sell": 23.9000, "timestamp": 1705954200000 },
			{ "buy": 23.8000, "sell": 23.9000, "timestamp": 1705954800000 },
			{ "buy": 23.8000, "sell": 23.9000, "timestamp": 1705955400000 },
			{ "buy": 23.8000, "sell": 23.9000, "timestamp": 1705956000000 },
			{ "buy": null, "sell": null, "timestamp": 1705956600000 },
			{ "buy": null, "sell": null, "timestamp": 1705957200000 },
			{ "buy": 23.5000, "sell": 23.9500, "timestamp": 1705993200000 },
			{ "buy": 23.5100, "sell": 23.9600, "timestamp": 1705993800000 },
			{ "buy": 23.5300, "sell": 23.9800, "timestamp": 1705994400000 },
			{ "buy": 23.5300, "sell": 23.9900, "timestamp": 1705995000000 },
			{ "buy": 23.5400, "sell": 24.0000, "timestamp": 1705995600000 },
			{ "buy": 23.5400, "sell": 23.9900, "timestamp": 1705996200000 },
			{ "buy": 23.5400, "sell": 24.0000, "timestamp": 1705996800000 },
			{ "buy": 23.5500, "sell": 24.0100, "timestamp": 1705997400000 },
			{ "buy": 23.5500, "sell": 24.0100, "timestamp": 1705998000000 },
			{ "buy": 23.9200, "sell": 24.0200, "timestamp": 1705998600000 },
			{ "buy": 24.0200, "sell": 24.1200, "timestamp": 1705999200000 },
			{ "buy": 24.0900, "sell": 24.1900, "timestamp": 1705999800000 },
			{ "buy": 24.2200, "sell": 24.3200, "timestamp": 1706000400000 },
			{ "buy": 24.1800, "sell": 24.2800, "timestamp": 1706001000000 },
			{ "buy": 24.1700, "sell": 24.2700, "timestamp": 1706001600000 },
			{ "buy": 24.0200, "sell": 24.1200, "timestamp": 1706002200000 },
			{ "buy": 24.0900, "sell": 24.1900, "timestamp": 1706002800000 },
			{ "buy": 24.0900, "sell": 24.1900, "timestamp": 1706003400000 },
			{ "buy": 23.9600, "sell": 24.0600, "timestamp": 1706004000000 },
			{ "buy": 24.0400, "sell": 24.1400, "timestamp": 1706004600000 },
			{ "buy": 24.1900, "sell": 24.2900, "timestamp": 1706005200000 },
			{ "buy": 24.2600, "sell": 24.3600, "timestamp": 1706005800000 },
			{ "buy": 24.2700, "sell": 24.3700, "timestamp": 1706006400000 },
			{ "buy": 24.2400, "sell": 24.3400, "timestamp": 1706007000000 },
			{ "buy": 24.3900, "sell": 24.4900, "timestamp": 1706007600000 },
			{ "buy": 24.2700, "sell": 24.3700, "timestamp": 1706008200000 },
			{ "buy": 24.1600, "sell": 24.2600, "timestamp": 1706008800000 },
			{ "buy": 24.2200, "sell": 24.3200, "timestamp": 1706009400000 },
			{ "buy": 24.2200, "sell": 24.3200, "timestamp": 1706010000000 },
			{ "buy": 24.2000, "sell": 24.3000, "timestamp": 1706010600000 },
			{ "buy": 23.9500, "sell": 24.0500, "timestamp": 1706011200000 },
			{ "buy": 23.9300, "sell": 24.0300, "timestamp": 1706011800000 },
			{ "buy": 23.7500, "sell": 23.8500, "timestamp": 1706012400000 },
			{ "buy": 23.6900, "sell": 23.7900, "timestamp": 1706013000000 },
			{ "buy": 23.7200, "sell": 23.8200, "timestamp": 1706013600000 },
			{ "buy": 23.7300, "sell": 23.8300, "timestamp": 1706014200000 },
			{ "buy": 23.8200, "sell": 23.9200, "timestamp": 1706014800000 },
			{ "buy": 23.7900, "sell": 23.8900, "timestamp": 1706015400000 },
			{ "buy": 23.8900, "sell": 23.9900, "timestamp": 1706016000000 },
			{ "buy": 23.8100, "sell": 23.9100, "timestamp": 1706016600000 },
			{ "buy": 23.6800, "sell": 23.7800, "timestamp": 1706017200000 },
			{ "buy": 23.4500, "sell": 23.5500, "timestamp": 1706017800000 },
			{ "buy": 23.5700, "sell": 23.6700, "timestamp": 1706018400000 },
			{ "buy": 23.3800, "sell": 23.4800, "timestamp": 1706019000000 },
			{ "buy": 23.0400, "sell": 23.1400, "timestamp": 1706019600000 },
			{ "buy": 22.8500, "sell": 22.9500, "timestamp": 1706020200000 },
			{ "buy": 22.7600, "sell": 22.8600, "timestamp": 1706020800000 },
			{ "buy": 22.7500, "sell": 22.8500, "timestamp": 1706021400000 },
			{ "buy": 22.6600, "sell": 22.7600, "timestamp": 1706022000000 },
			{ "buy": 22.7200, "sell": 22.8200, "timestamp": 1706022600000 },
			{ "buy": 22.9300, "sell": 23.0300, "timestamp": 1706023200000 },
			{ "buy": 23.1200, "sell": 23.2200, "timestamp": 1706023800000 },
			{ "buy": 23.2400, "sell": 23.3400, "timestamp": 1706024400000 },
			{ "buy": 23.5700, "sell": 23.6700, "timestamp": 1706025000000 },
			{ "buy": 23.3100, "sell": 23.4100, "timestamp": 1706025600000 },
			{ "buy": 23.3600, "sell": 23.4600, "timestamp": 1706026200000 },
			{ "buy": 23.0300, "sell": 23.1300, "timestamp": 1706026800000 },
			{ "buy": 22.9500, "sell": 23.0500, "timestamp": 1706027400000 },
			{ "buy": 23.0600, "sell": 23.1600, "timestamp": 1706028000000 },
			{ "buy": 22.9600, "sell": 23.0600, "timestamp": 1706028600000 },
			{ "buy": 23.0800, "sell": 23.1800, "timestamp": 1706029200000 },
			{ "buy": 22.9900, "sell": 23.0900, "timestamp": 1706029800000 },
			{ "buy": 23.0000, "sell": 23.1000, "timestamp": 1706030400000 },
			{ "buy": 23.2000, "sell": 23.3000, "timestamp": 1706031000000 },
			{ "buy": 23.1600, "sell": 23.2600, "timestamp": 1706031600000 },
			{ "buy": 23.1400, "sell": 23.2300, "timestamp": 1706032200000 },
			{ "buy": null, "sell": null, "timestamp": 1706032800000 },
			{ "buy": null, "sell": null, "timestamp": 1706033400000 },
			{ "buy": null, "sell": null, "timestamp": 1706034000000 },
			{ "buy": null, "sell": null, "timestamp": 1706034600000 },
			{ "buy": null, "sell": null, "timestamp": 1706035200000 },
			{ "buy": null, "sell": null, "timestamp": 1706035800000 },
			{ "buy": null, "sell": null, "timestamp": 1706036400000 },
			{ "buy": null, "sell": null, "timestamp": 1706037000000 },
			{ "buy": null, "sell": null, "timestamp": 1706037600000 },
			{ "buy": null, "sell": null, "timestamp": 1706038200000 },
			{ "buy": null, "sell": null, "timestamp": 1706038800000 },
			{ "buy": null, "sell": null, "timestamp": 1706039400000 },
			{ "buy": null, "sell": null, "timestamp": 1706040000000 },
			{ "buy": null, "sell": null, "timestamp": 1706040600000 },
			{ "buy": null, "sell": null, "timestamp": 1706041200000 },
			{ "buy": null, "sell": null, "timestamp": 1706041800000 },
			{ "buy": null, "sell": null, "timestamp": 1706042400000 },
			{ "buy": null, "sell": null, "timestamp": 1706043000000 },
			{ "buy": null, "sell": null, "timestamp": 1706043600000 },
			{ "buy": 22.8700, "sell": 23.3100, "timestamp": 1706079600000 },
			{ "buy": 22.8700, "sell": 23.3100, "timestamp": 1706080200000 },
			{ "buy": 22.8700, "sell": 23.3100, "timestamp": 1706080800000 },
			{ "buy": 22.8500, "sell": 23.2900, "timestamp": 1706081400000 },
			{ "buy": 22.8500, "sell": 23.2900, "timestamp": 1706082000000 },
			{ "buy": 22.8500, "sell": 23.2900, "timestamp": 1706082600000 },
			{ "buy": 22.8300, "sell": 23.2700, "timestamp": 1706083200000 },
			{ "buy": 22.8500, "sell": 23.2900, "timestamp": 1706083800000 },
			{ "buy": 22.8600, "sell": 23.3000, "timestamp": 1706084400000 },
			{ "buy": 22.7700, "sell": 22.8600, "timestamp": 1706085000000 },
			{ "buy": 22.8400, "sell": 22.9300, "timestamp": 1706085600000 },
			{ "buy": 22.6900, "sell": 22.7800, "timestamp": 1706086200000 },
			{ "buy": 22.6000, "sell": 22.6900, "timestamp": 1706086800000 },
			{ "buy": 22.5800, "sell": 22.6700, "timestamp": 1706087400000 },
			{ "buy": 22.6100, "sell": 22.7000, "timestamp": 1706088000000 },
			{ "buy": 22.5400, "sell": 22.6300, "timestamp": 1706088600000 },
			{ "buy": 22.7100, "sell": 22.8000, "timestamp": 1706089200000 },
			{ "buy": 22.5300, "sell": 22.6200, "timestamp": 1706089800000 },
			{ "buy": 22.5200, "sell": 22.6100, "timestamp": 1706090400000 },
			{ "buy": 22.6100, "sell": 22.7000, "timestamp": 1706091000000 },
			{ "buy": 22.5200, "sell": 22.6100, "timestamp": 1706091600000 },
			{ "buy": 22.5200, "sell": 22.6100, "timestamp": 1706092200000 },
			{ "buy": 22.6200, "sell": 22.7100, "timestamp": 1706092800000 },
			{ "buy": 22.6200, "sell": 22.7100, "timestamp": 1706093400000 },
			{ "buy": 22.6800, "sell": 22.7700, "timestamp": 1706094000000 },
			{ "buy": 22.6000, "sell": 22.6900, "timestamp": 1706094600000 },
			{ "buy": 22.5700, "sell": 22.6600, "timestamp": 1706095200000 },
			{ "buy": 22.5400, "sell": 22.6300, "timestamp": 1706095800000 },
			{ "buy": 22.5200, "sell": 22.6100, "timestamp": 1706096400000 },
			{ "buy": 22.5400, "sell": 22.6300, "timestamp": 1706097000000 },
			{ "buy": 22.5500, "sell": 22.6400, "timestamp": 1706097600000 },
			{ "buy": 22.5100, "sell": 22.6000, "timestamp": 1706098200000 },
			{ "buy": 22.5300, "sell": 22.6200, "timestamp": 1706098800000 },
			{ "buy": 22.5500, "sell": 22.6400, "timestamp": 1706099400000 },
			{ "buy": 22.5800, "sell": 22.6700, "timestamp": 1706100000000 },
			{ "buy": 22.8400, "sell": 22.9300, "timestamp": 1706100600000 },
			{ "buy": 23.1800, "sell": 23.2700, "timestamp": 1706101200000 },
			{ "buy": 23.0400, "sell": 23.1300, "timestamp": 1706101800000 },
			{ "buy": 23.0600, "sell": 23.1500, "timestamp": 1706102400000 },
			{ "buy": 23.0800, "sell": 23.1700, "timestamp": 1706103000000 },
			{ "buy": 22.9700, "sell": 23.0600, "timestamp": 1706103600000 },
			{ "buy": 23.0400, "sell": 23.1300, "timestamp": 1706104200000 },
			{ "buy": 22.9300, "sell": 23.0200, "timestamp": 1706104800000 },
			{ "buy": 22.7700, "sell": 22.8600, "timestamp": 1706105400000 },
			{ "buy": 22.9400, "sell": 23.0300, "timestamp": 1706106000000 },
			{ "buy": 23.5000, "sell": 23.5900, "timestamp": 1706106600000 },
			{ "buy": 23.2700, "sell": 23.3600, "timestamp": 1706107200000 },
			{ "buy": 23.3000, "sell": 23.3900, "timestamp": 1706107800000 },
			{ "buy": 22.9900, "sell": 23.0800, "timestamp": 1706108400000 },
			{ "buy": 22.2000, "sell": 22.2900, "timestamp": 1706109000000 },
			{ "buy": 21.7900, "sell": 21.8800, "timestamp": 1706109600000 },
			{ "buy": 21.8900, "sell": 21.9800, "timestamp": 1706110200000 },
			{ "buy": 21.6100, "sell": 21.7000, "timestamp": 1706110800000 },
			{ "buy": 21.5300, "sell": 21.6200, "timestamp": 1706111400000 },
			{ "buy": 21.1400, "sell": 21.2300, "timestamp": 1706112000000 },
			{ "buy": 20.9900, "sell": 21.0800, "timestamp": 1706112600000 },
			{ "buy": 21.1000, "sell": 21.1900, "timestamp": 1706113200000 },
			{ "buy": 21.1000, "sell": 21.1900, "timestamp": 1706113800000 },
			{ "buy": 21.1000, "sell": 21.1900, "timestamp": 1706114400000 },
			{ "buy": 21.0200, "sell": 21.1100, "timestamp": 1706115000000 },
			{ "buy": 21.0800, "sell": 21.1700, "timestamp": 1706115600000 },
			{ "buy": 21.0500, "sell": 21.1400, "timestamp": 1706116200000 },
			{ "buy": 21.0700, "sell": 21.1600, "timestamp": 1706116800000 },
			{ "buy": 21.2900, "sell": 21.3800, "timestamp": 1706117400000 },
			{ "buy": 21.0600, "sell": 21.1500, "timestamp": 1706118000000 },
			{ "buy": 20.7900, "sell": 20.8700, "timestamp": 1706118600000 },
			{ "buy": 20.8200, "sell": 20.9000, "timestamp": 1706119200000 },
			{ "buy": 20.8200, "sell": 20.9000, "timestamp": 1706119800000 },
			{ "buy": 20.8500, "sell": 20.9300, "timestamp": 1706120400000 },
			{ "buy": 20.8600, "sell": 20.9400, "timestamp": 1706121000000 },
			{ "buy": 20.8700, "sell": 20.9500, "timestamp": 1706121600000 },
			{ "buy": 20.8600, "sell": 20.9400, "timestamp": 1706122200000 },
			{ "buy": 20.8700, "sell": 20.9500, "timestamp": 1706122800000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706123400000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706124000000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706124600000 },
			{ "buy": 20.8700, "sell": 20.9500, "timestamp": 1706125200000 },
			{ "buy": 20.8700, "sell": 20.9500, "timestamp": 1706125800000 },
			{ "buy": 20.8700, "sell": 20.9500, "timestamp": 1706126400000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706127000000 },
			{ "buy": 20.8900, "sell": 20.9700, "timestamp": 1706127600000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706128200000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706128800000 },
			{ "buy": 20.9000, "sell": 20.9800, "timestamp": 1706129400000 },
			{ "buy": null, "sell": null, "timestamp": 1706130000000 },
			{ "buy": 20.7200, "sell": 21.1100, "timestamp": 1706166000000 },
			{ "buy": 20.7200, "sell": 21.1100, "timestamp": 1706166600000 },
			{ "buy": 20.7200, "sell": 21.1100, "timestamp": 1706167200000 },
			{ "buy": 20.7300, "sell": 21.1200, "timestamp": 1706167800000 },
			{ "buy": 20.7300, "sell": 21.1200, "timestamp": 1706168400000 },
			{ "buy": 20.7000, "sell": 21.0900, "timestamp": 1706169000000 },
			{ "buy": 20.7000, "sell": 21.0900, "timestamp": 1706169600000 },
			{ "buy": 20.7200, "sell": 21.1100, "timestamp": 1706170200000 },
			{ "buy": 20.8200, "sell": 21.2100, "timestamp": 1706170800000 },
			{ "buy": 20.8400, "sell": 20.9200, "timestamp": 1706171400000 },
			{ "buy": 21.0000, "sell": 21.0800, "timestamp": 1706172000000 },
			{ "buy": 20.9800, "sell": 21.0600, "timestamp": 1706172600000 },
			{ "buy": 20.9500, "sell": 21.0300, "timestamp": 1706173200000 },
			{ "buy": 20.9500, "sell": 21.0300, "timestamp": 1706173800000 },
			{ "buy": 20.8200, "sell": 20.9000, "timestamp": 1706174400000 },
			{ "buy": 21.1300, "sell": 21.2100, "timestamp": 1706175000000 },
			{ "buy": 20.9600, "sell": 21.0400, "timestamp": 1706175600000 },
			{ "buy": 20.9800, "sell": 21.0600, "timestamp": 1706176200000 },
			{ "buy": 20.9400, "sell": 21.0200, "timestamp": 1706176800000 },
			{ "buy": 21.0700, "sell": 21.1500, "timestamp": 1706177400000 },
			{ "buy": 21.1300, "sell": 21.2100, "timestamp": 1706178000000 },
			{ "buy": 21.1900, "sell": 21.2700, "timestamp": 1706178600000 },
			{ "buy": 21.2000, "sell": 21.2800, "timestamp": 1706179200000 },
			{ "buy": 21.2800, "sell": 21.3600, "timestamp": 1706179800000 },
			{ "buy": 21.3400, "sell": 21.4200, "timestamp": 1706180400000 },
			{ "buy": 21.2500, "sell": 21.3300, "timestamp": 1706181000000 },
			{ "buy": 21.1800, "sell": 21.2600, "timestamp": 1706181600000 },
			{ "buy": 21.1100, "sell": 21.1900, "timestamp": 1706182200000 },
			{ "buy": 20.7700, "sell": 20.8500, "timestamp": 1706182800000 },
			{ "buy": 20.8600, "sell": 20.9400, "timestamp": 1706183400000 },
			{ "buy": 20.9700, "sell": 21.0500, "timestamp": 1706184000000 },
			{ "buy": 21.0200, "sell": 21.1000, "timestamp": 1706184600000 },
			{ "buy": 21.0000, "sell": 21.0800, "timestamp": 1706185200000 },
			{ "buy": 20.9400, "sell": 21.0200, "timestamp": 1706185800000 },
			{ "buy": 20.9100, "sell": 20.9900, "timestamp": 1706186400000 },
			{ "buy": 21.1800, "sell": 21.2600, "timestamp": 1706187000000 },
			{ "buy": 20.9100, "sell": 20.9900, "timestamp": 1706187600000 },
			{ "buy": 20.8800, "sell": 20.9600, "timestamp": 1706188200000 },
			{ "buy": 20.7100, "sell": 20.7900, "timestamp": 1706188800000 },
			{ "buy": 20.7500, "sell": 20.8300, "timestamp": 1706189400000 },
			{ "buy": 20.8600, "sell": 20.9400, "timestamp": 1706190000000 },
			{ "buy": 20.8400, "sell": 20.9200, "timestamp": 1706190600000 },
			{ "buy": 20.8000, "sell": 20.8800, "timestamp": 1706191200000 },
			{ "buy": 20.5600, "sell": 20.6400, "timestamp": 1706191800000 },
			{ "buy": 20.6100, "sell": 20.6900, "timestamp": 1706192400000 },
			{ "buy": 20.6700, "sell": 20.7500, "timestamp": 1706193000000 },
			{ "buy": 21.3200, "sell": 21.4000, "timestamp": 1706193600000 },
			{ "buy": 21.4600, "sell": 21.5400, "timestamp": 1706194200000 },
			{ "buy": 21.6000, "sell": 21.6800, "timestamp": 1706194800000 },
			{ "buy": 21.6500, "sell": 21.7300, "timestamp": 1706195400000 },
			{ "buy": 21.7400, "sell": 21.8200, "timestamp": 1706196000000 },
			{ "buy": 21.4500, "sell": 21.5300, "timestamp": 1706196600000 },
			{ "buy": 21.0600, "sell": 21.1400, "timestamp": 1706197200000 },
			{ "buy": 21.4000, "sell": 21.4800, "timestamp": 1706197800000 },
			{ "buy": 21.2100, "sell": 21.2900, "timestamp": 1706198400000 },
			{ "buy": 21.4400, "sell": 21.5200, "timestamp": 1706199000000 },
			{ "buy": 21.3500, "sell": 21.4300, "timestamp": 1706199600000 },
			{ "buy": 21.3500, "sell": 21.4300, "timestamp": 1706200200000 },
			{ "buy": 21.2800, "sell": 21.3600, "timestamp": 1706200800000 },
			{ "buy": 21.2300, "sell": 21.3100, "timestamp": 1706201400000 },
			{ "buy": 21.3900, "sell": 21.4700, "timestamp": 1706202000000 },
			{ "buy": 21.3800, "sell": 21.4600, "timestamp": 1706202600000 },
			{ "buy": 21.3700, "sell": 21.4500, "timestamp": 1706203200000 },
			{ "buy": 21.7000, "sell": 21.7800, "timestamp": 1706203800000 },
			{ "buy": 21.8900, "sell": 21.9700, "timestamp": 1706204400000 },
			{ "buy": 22.1400, "sell": 22.3100, "timestamp": 1706205000000 },
			{ "buy": 22.1100, "sell": 22.2800, "timestamp": 1706205600000 },
			{ "buy": 22.1400, "sell": 22.3100, "timestamp": 1706206200000 },
			{ "buy": 22.1500, "sell": 22.3200, "timestamp": 1706206800000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706207400000 },
			{ "buy": 22.1600, "sell": 22.3300, "timestamp": 1706208000000 },
			{ "buy": 22.1900, "sell": 22.3600, "timestamp": 1706208600000 },
			{ "buy": 22.1900, "sell": 22.3600, "timestamp": 1706209200000 },
			{ "buy": 22.1900, "sell": 22.3600, "timestamp": 1706209800000 },
			{ "buy": 22.1800, "sell": 22.3500, "timestamp": 1706210400000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706211000000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706211600000 },
			{ "buy": 22.1600, "sell": 22.3300, "timestamp": 1706212200000 },
			{ "buy": 22.1600, "sell": 22.3300, "timestamp": 1706212800000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706213400000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706214000000 },
			{ "buy": 22.1700, "sell": 22.3400, "timestamp": 1706214600000 },
			{ "buy": 22.1600, "sell": 22.3300, "timestamp": 1706215200000 },
			{ "buy": null, "sell": null, "timestamp": 1706215800000 },
			{ "buy": null, "sell": null, "timestamp": 1706216400000 },
			{ "buy": 22.0600, "sell": 22.4700, "timestamp": 1706252400000 },
			{ "buy": 22.0700, "sell": 22.4800, "timestamp": 1706253000000 },
			{ "buy": 22.0500, "sell": 22.4600, "timestamp": 1706253600000 },
			{ "buy": 22.0300, "sell": 22.4400, "timestamp": 1706254200000 },
			{ "buy": 22.0500, "sell": 22.4600, "timestamp": 1706254800000 },
			{ "buy": 22.0500, "sell": 22.4600, "timestamp": 1706255400000 },
			{ "buy": 22.0600, "sell": 22.4700, "timestamp": 1706256000000 },
			{ "buy": 22.0500, "sell": 22.4600, "timestamp": 1706256600000 },
			{ "buy": 22.0400, "sell": 22.4500, "timestamp": 1706257200000 },
			{ "buy": 22.3200, "sell": 22.4100, "timestamp": 1706257800000 },
			{ "buy": 22.2900, "sell": 22.3800, "timestamp": 1706258400000 },
			{ "buy": 22.1400, "sell": 22.2300, "timestamp": 1706259000000 },
			{ "buy": 22.2200, "sell": 22.3100, "timestamp": 1706259600000 },
			{ "buy": 22.3700, "sell": 22.4600, "timestamp": 1706260200000 },
			{ "buy": 22.2000, "sell": 22.2900, "timestamp": 1706260800000 },
			{ "buy": 22.0300, "sell": 22.1200, "timestamp": 1706261400000 },
			{ "buy": 21.9600, "sell": 22.0500, "timestamp": 1706262000000 },
			{ "buy": 21.9600, "sell": 22.0500, "timestamp": 1706262600000 },
			{ "buy": 22.0100, "sell": 22.1000, "timestamp": 1706263200000 },
			{ "buy": 21.9500, "sell": 22.0400, "timestamp": 1706263800000 },
			{ "buy": 21.9100, "sell": 22.0000, "timestamp": 1706264400000 },
			{ "buy": 21.8900, "sell": 21.9800, "timestamp": 1706265000000 },
			{ "buy": 21.7900, "sell": 21.8800, "timestamp": 1706265600000 },
			{ "buy": 21.7800, "sell": 21.8700, "timestamp": 1706266200000 },
			{ "buy": 21.7300, "sell": 21.8200, "timestamp": 1706266800000 },
			{ "buy": 21.6600, "sell": 21.7500, "timestamp": 1706267400000 },
			{ "buy": 21.6000, "sell": 21.6900, "timestamp": 1706268000000 },
			{ "buy": 21.6700, "sell": 21.7600, "timestamp": 1706268600000 },
			{ "buy": 21.7700, "sell": 21.8600, "timestamp": 1706269200000 },
			{ "buy": 21.9600, "sell": 22.0500, "timestamp": 1706269800000 },
			{ "buy": 21.8300, "sell": 21.9200, "timestamp": 1706270400000 },
			{ "buy": 21.6100, "sell": 21.7000, "timestamp": 1706271000000 },
			{ "buy": 21.6300, "sell": 21.7200, "timestamp": 1706271600000 },
			{ "buy": 21.4800, "sell": 21.5700, "timestamp": 1706272200000 },
			{ "buy": 21.4500, "sell": 21.5400, "timestamp": 1706272800000 },
			{ "buy": 21.4800, "sell": 21.5700, "timestamp": 1706273400000 },
			{ "buy": 21.5000, "sell": 21.5900, "timestamp": 1706274000000 },
			{ "buy": 21.7000, "sell": 21.7900, "timestamp": 1706274600000 },
			{ "buy": 21.5700, "sell": 21.6600, "timestamp": 1706275200000 },
			{ "buy": 21.8800, "sell": 21.9700, "timestamp": 1706275800000 },
			{ "buy": 21.7600, "sell": 21.8500, "timestamp": 1706276400000 },
			{ "buy": 21.9700, "sell": 22.0600, "timestamp": 1706277000000 },
			{ "buy": 22.0200, "sell": 22.1100, "timestamp": 1706277600000 },
			{ "buy": 22.1200, "sell": 22.2100, "timestamp": 1706278200000 },
			{ "buy": 22.3800, "sell": 22.4700, "timestamp": 1706278800000 }
		]
}
